import React, { useState, useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import Logo from "../../../assets/icons/biglogo.svg";

const BottomNav = ({ internshipCountries, universityCountries }) => {
  const location = useLocation();
  const [hambarClk, setHamBarClk] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const hambarClick = () => {
    setHamBarClk((prev) => !prev);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 35);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="!w-full !overflow-x-hidden">
      <div
        className={`w-full max-w-[1600px] mx-auto bg-slate-50 shadow font-poppins z-50 ${
          isSticky ? "fixed top-0" : "absolute top-[35px]"
        }`}
      >
        <div className="h-[60px] w-full max-w-[1600px] mx-auto">
          <div className="flex w-full lg:w-[95%] mx-auto justify-between items-center h-full">
            <div className="flex gap-20 h-full w-full justify-between">
              <div className="logo flex justify-center items-center h-full">
                <Link to={"/"}>
                  <img
                    loading="lazy"
                    src={Logo}
                    height={60}
                    width={190}
                    alt="logo"
                    className="z-50 ml-3 lg:ml-0"
                  />
                </Link>
              </div>
              <div
                className={`lg:opacity-100 transition-opacity duration-300 ${
                  hambarClk ? "opacity-100 block" : "opacity-0 hidden lg:block"
                }`}
              >
                <ul
                  className={`flex text-sm justify-center gap-8 lg:gap-5 xl:gap-9 items-center flex-col h-screen w-full lg:flex-row lg:h-full bg-white lg:bg-transparent transition-all duration-300 sm:w-[50%] lg:w-full absolute lg:static top-0 lg:top-auto bottom-0 ${
                    hambarClk ? "right-0" : "right-[-100vw]"
                  } `}
                >
                  <li>
                    <Link
                      onClick={() => setHamBarClk(false)}
                      className={`${
                        location.pathname === "/"
                          ? "text-black"
                          : "text-slate-700"
                      } relative hover:text-black py-2 `}
                      to={"/"}
                    >
                      Home
                      {location.pathname === "/" && (
                        <div className="h-[0.19rem] absolute bottom-1 bg-black/80 rounded-r w-[20px]"></div>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setHamBarClk(false)}
                      className={` ${
                        location.pathname.includes("/about-us")
                          ? "text-black"
                          : "text-slate-700"
                      } hover:text-black relative py-2 `}
                      to={"/about-us"}
                    >
                      About Us
                      {location.pathname.includes("/about-us") && (
                        <div className="h-[0.19rem] absolute bottom-1 bg-black/80 rounded-r w-[20px]"></div>
                      )}
                    </Link>
                  </li>
                  <li className="relative group">
                    <div
                      // onClick={() => setHamBarClk(false)}
                      className={`${
                        location.pathname.includes("/study-abroad")
                          ? "text-black"
                          : "text-slate-700"
                      } hover:text-black relative group  py-2`}
                    >
                      <span className="flex items-center gap-0.5 py-2">
                        Study Abroad
                        <FaAngleDown className="group-hover:rotate-180 duration-200" />
                      </span>
                      {location.pathname.includes("/study-abroad") && (
                        <div className="h-[0.19rem] absolute bottom-1 bg-black/80 rounded-r w-[20px]"></div>
                      )}
                    </div>
                    <div className="hidden group-hover:block z-40 absolute bg-white shadow-lg rounded-lg">
                      <ul>
                        {universityCountries.map((e) => (
                          <li key={e.id}>
                            <Link
                              onClick={() => setHamBarClk(false)}
                              to={`/study-abroad/${e.name.toLowerCase()}`}
                              className={`block px-4 py-2 text-nowrap hover:bg-gray-100 rounded-lg ${
                                location.pathname ===
                                `/study-abroad/${e.name.toLowerCase()}`
                                  ? "bg-gray-200"
                                  : ""
                              }`}
                            >
                              Study in {e.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  <li className="relative group">
                    <div
                      // onClick={() => setHamBarClk(false)}
                      className={`${
                        location.pathname.includes("/internship-abroad")
                          ? "text-black"
                          : "text-slate-700"
                      } hover:text-black relative group  py-2`}
                    >
                      <span className="flex items-center gap-0.5 py-2">
                        Internship Abroad
                        <FaAngleDown className="group-hover:rotate-180 duration-200" />
                      </span>
                      {location.pathname.includes("/internship-abroad") && (
                        <div className="h-[0.19rem] absolute bottom-1 bg-black/80 rounded-r w-[20px]"></div>
                      )}
                    </div>
                    <div className="hidden group-hover:block absolute bg-white z-40 shadow-lg rounded-lg">
                      <ul>
                        {internshipCountries.map((e) => (
                          <li key={e.id}>
                            <Link
                              onClick={() => setHamBarClk(false)}
                              to={`/internship-abroad/${e.name.toLowerCase()}`}
                              className={`block px-4 py-2 text-nowrap hover:bg-gray-100 rounded-lg ${
                                location.pathname.includes(
                                  `/internship-abroad/${e.name.toLowerCase()}`
                                )
                                  ? "bg-gray-200"
                                  : ""
                              }`}
                            >
                              Internship in {e.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  <li>
                    <Link
                      onClick={() => setHamBarClk(false)}
                      className={`bg-[#FF3D00] flex justify-center items-center gap-1 text-white px-4 rounded-full hover:text-white duration-150 hover:bg-[#7668FF] relative py-[0.44rem] `}
                      to={"/contacts"}
                    >
                      <FaPhoneAlt />
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:hidden w-[22px] mr-6">
              <div
                onClick={hambarClick}
                className="relative items-end scale-125 flex transition-all z-40 duration-150 flex-col gap-[0.2rem] cursor-pointer"
              >
                <div
                  className={`h-[0.2rem] transition-all duration-150 bg-black/90 rounded-full ${
                    hambarClk
                      ? "-rotate-45 absolute top-0 left-0 w-[22px]"
                      : "w-[10px]"
                  }`}
                ></div>
                <div
                  className={`h-[0.2rem] w-[15px] transition-all duration-150 bg-black/90 rounded-full ${
                    hambarClk ? "hidden" : "block"
                  }`}
                ></div>
                <div
                  className={`h-[0.2rem] w-[22px] transition-all duration-150 bg-black/90 rounded-full ${
                    hambarClk ? "rotate-45 absolute top-0 left-0" : ""
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;

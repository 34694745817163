import React, { useRef, useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Textarea,
  Checkbox,
  Progress,
} from "@nextui-org/react";
import { PlusIcon } from "../../../common/components/Tables/Icons/PlusIcon";

export default function ModalApp(props) {
  const {
    handelPost,
    updateBtnRef,
    handelUpdate,
    updateData,
    setUpdateData,
    postUpload,
  } = props;
  const closeBtnRef = useRef();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [bannerData, setBannerData] = useState({
    color_heading: "",
    non_color_heading: "",
    bannerDescription: "",
    bannerActive: true,
    bannerImage: null,
  });

  const [validationErrors, setValidationErrors] = useState({
    color_heading: "",
    non_color_heading: "",
  });

  useEffect(() => {
    setBannerData({
      color_heading: updateData.status ? updateData.data.color_heading : "",
      non_color_heading: updateData.status
        ? updateData.data.non_color_heading
        : "",
      bannerDescription: updateData.status ? updateData.data.description : "",
      bannerActive: updateData.status ? updateData.data.active : true,
      bannerImage: updateData.status ? updateData.data.image : null,
    });
  }, [updateData]);

  const bannerDataChange = (e) => {
    if (e.target.name === "bannerImage") {
      setBannerData({ ...bannerData, bannerImage: e.target.files[0] });
    } else if (e.target.name === "bannerActive") {
      setBannerData({ ...bannerData, bannerActive: e.target.checked });
    } else {
      setBannerData({ ...bannerData, [e.target.name]: e.target.value });
    }
  };

  const clearBannerData = () => {
    setBannerData({
      color_heading: "",
      non_color_heading: "",
      bannerDescription: "",
      bannerActive: true,
      bannerImage: null,
    });
    setUpdateData({ status: false, data: {} });
    setValidationErrors({
      color_heading: "",
      non_color_heading: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationErrors = {
      color_heading: "",
      non_color_heading: "",
      bannerDescription: "",
      bannerImage: "",
    };

    if (!bannerData.color_heading.trim()) {
      newValidationErrors.color_heading = "Banner Title is required";
      isValid = false;
    }

    if (!bannerData.bannerDescription.trim()) {
      newValidationErrors.bannerDescription = "Banner description is required";
      isValid = false;
    }
    if (!bannerData.bannerImage) {
      newValidationErrors.bannerImage = "Banner Image is required";
      isValid = false;
    }

    // Image is optional, but if selected, it should be less than 800KB
    if (bannerData?.bannerImage?.size > 800 * 1024) {
      newValidationErrors.bannerImage = "Image size should not exceed 800KB";
      isValid = false;
    }

    if (bannerData.non_color_heading.length < 1) {
      newValidationErrors.non_color_heading =
        "Banner non_color_heading is required";
      isValid = false;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  const bannerDataSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (updateData.status) {
        handelUpdate({ ...bannerData, id: updateData.data.id });
      } else {
        handelPost(bannerData);
      }
    }
  };
  useEffect(() => {
    if (postUpload === 100) {
      setTimeout(() => {
        closeBtnRef.current?.click();
      }, 2000);
    }
  }, [postUpload]);

  return (
    <>
      <Button
        radius="sm"
        onPress={onOpen}
        color="primary"
        endContent={<PlusIcon />}
        ref={updateBtnRef}
        className="!hidden"
      >
        Add New
      </Button>
      <Modal
        isOpen={isOpen}
        radius="sm"
        size="xl"
        onOpenChange={onOpenChange}
        onClose={() => {
          clearBannerData();
          onOpenChange();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <form method="post" onSubmit={bannerDataSubmit}>
              <ModalHeader className="flex flex-col gap-1 uppercase text-slate-600 dark:text-slate-200">
                Add Banner
              </ModalHeader>
              <ModalBody>
                <div className="flex gap-3 w-full">
                  <div className="flex flex-col w-1/2">
                    <Input
                      type="text"
                      label="Banner color heading"
                      radius="sm"
                      size="sm"
                      variant="underlined"
                      name="color_heading"
                      value={bannerData.color_heading}
                      onChange={bannerDataChange}
                    />
                    {validationErrors.color_heading && (
                      <div className="text-red-500 !mt-1 errorFront">
                        {validationErrors.color_heading}
                      </div>
                    )}
                  </div>
                  <div className="w-1/2">
                    <Input
                      type="text"
                      label="Banner non-color heading"
                      radius="sm"
                      size="sm"
                      variant="underlined"
                      name="non_color_heading"
                      value={bannerData.non_color_heading}
                      onChange={bannerDataChange}
                    />
                    {validationErrors.non_color_heading && (
                      <div className="text-red-500 !mt-1 errorFront">
                        {validationErrors.non_color_heading}
                      </div>
                    )}
                  </div>
                </div>
                <Input
                  type="file"
                  variant="underlined"
                  label="Banner Image"
                  placeholder="E"
                  radius="none"
                  className="!p-0 paddintyzero"
                  accept=".jpg, .jpeg, .png, .gif, .bmp, .webp"
                  name="bannerImage"
                  onChange={bannerDataChange}
                />
                {validationErrors.bannerImage && (
                  <div className="text-red-500 errorFront">
                    {validationErrors.bannerImage}
                  </div>
                )}
                <Textarea
                  variant="underlined"
                  label="Banner Description"
                  name="bannerDescription"
                  value={bannerData.bannerDescription}
                  onChange={bannerDataChange}
                />
                {validationErrors.bannerDescription && (
                  <div className="text-red-500 errorFront">
                    {validationErrors.bannerDescription}
                  </div>
                )}
                <div className="flex w-full">
                  {updateData.status ? (
                    <Checkbox
                      defaultSelected={updateData.data.active}
                      size="md"
                      className="mt-1"
                      name="bannerActive"
                      value={bannerData.bannerActive}
                      onChange={bannerDataChange}
                    >
                      Active Banner
                    </Checkbox>
                  ) : (
                    <Checkbox
                      defaultSelected={bannerData.bannerActive}
                      size="md"
                      className="mt-1"
                      name="bannerActive"
                      value={bannerData.bannerActive}
                      onChange={bannerDataChange}
                    >
                      Active Banner
                    </Checkbox>
                  )}
                </div>
                {postUpload > 0 && (
                  <Progress
                    aria-label="Downloading..."
                    size="sm"
                    value={postUpload}
                    className="mt-1"
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <div className="flex gap-3 flex-row-reverse">
                  <Button
                    color="primary"
                    size="sm"
                    radius="none"
                    className="rounded-sm !uppercase ml-2 tracking-wide"
                    type="submit"
                  >
                    Post
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    variant="bordered"
                    onPress={onClose}
                    radius="none"
                    className="rounded-sm !uppercase tracking-wide"
                    type="reset"
                    ref={closeBtnRef}
                    isDisabled={postUpload !== 0}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

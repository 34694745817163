import "./css/widget.css";
import Final from "./Final";
import { FaGlobeAmericas, FaUniversity } from "react-icons/fa";
import { MdOutlineWork, MdRateReview } from "react-icons/md";

const Widget = ({ type, dataBack }) => {
  let data;

  switch (type) {
    case "countries":
      data = {
        title: "COUNTRIES",
        isMoney: false,
        link: "See all countries",
        hrefLink: "/dashboard/countries",
        all: dataBack,
        icon: (
          <FaGlobeAmericas
            className="icon p-1 rounded !text-2xl"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "universities":
      data = {
        title: "UNIVERSITIES",
        isMoney: false,
        link: "View all universities",
        hrefLink: "/dashboard/university",
        all: dataBack,
        icon: (
          <FaUniversity
            className="icon p-1 rounded !text-2xl"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "works":
      data = {
        title: "WORKS",
        isMoney: false,
        link: "View all works",
        hrefLink: "/dashboard/work",
        all: dataBack,
        icon: (
          <MdOutlineWork
            className="icon p-1 rounded !text-2xl"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "reviews":
      data = {
        title: "REVIEWS",
        isMoney: false,
        link: "View all reviews",
        hrefLink: "/dashboard/reviews",
        all: dataBack,
        icon: (
          <MdRateReview
            className="icon p-1 rounded !text-2xl"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return <Final data={data} />;
};

export default Widget;

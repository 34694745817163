import React, { useEffect, useState } from "react";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import { API_BASE_URL } from "../../../redux/config";

const Navigation = () => {
  const [universityCountries, setUniversityCountries] = useState([]);
  const [internshipCountries, setInternshipCountries] = useState([]);

  useEffect(() => {
    const fetchData = async (url, setter) => {
      try {
        const response = await fetch(url);
        if (response.ok) {
          setter(await response.json());
        } else {
          console.error(`Failed to fetch from ${url}`);
        }
      } catch (error) {
        console.error(`Error fetching from ${url}:`, error);
      }
    };

    fetchData(
      `${API_BASE_URL}/api/countries/countries/university`,
      setUniversityCountries
    );
    fetchData(
      `${API_BASE_URL}/api/countries/countries/internship`,
      setInternshipCountries
    );
  }, []);

  return (
    <div className="overflow-x-hidden">
      <TopNav />
      <BottomNav
        universityCountries={universityCountries}
        internshipCountries={internshipCountries}
      />
    </div>
  );
};

export default Navigation;

import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Devider from "./Devider";

const HeroEachPage = ({ data }) => {
  return (
    <>
      <div className="bg-[#EAF2FF] h-[710px] md:h-[690px] lg:h-[550px] w-full pt-[0px] flex justify-center items-center relative flex-col">
        <div className="w-full flex flex-col lg:flex-row mt-[50px] lg:mt-[65px] gap-1 h-full">
          <div className="w-full lg:w-1/2 pt-[40px] lg:pt-[120px]">
            <div className="w-full">
              <div className="w-[90%] md:w-[80%] lg:w-[80%] mx-auto lg:float-end flex gap-4 lg:gap-6 flex-col justify-center lg:justify-start items-center lg:items-start">
                <h1 className="text-3xl md:text-4xl lg:text-[4rem] font-bold text-center lg:text-left flex capitalize items-center gap-4">
                  <span className="text-[#7668FF]">{data.color_heading}</span>
                  {data.non_color_heading}
                </h1>
                <p className="text-base md:text-lg lg:text-xl ml-1 text-black/70 text-center lg:text-left">
                  {data.description}
                </p>
                <div className="flex justify-center lg:justify-start items-center ">
                  <Link
                    className={`bg-[#FF3D00] hover:tracking-wide flex justify-center items-center gap-1.5 text-white px-8 rounded-full hover:text-white scale-80 md:scale-100 duration-150 hover:bg-[#7668FF] relative py-[0.6rem]`}
                    to={"/about-us"}
                  >
                    <span className="md:mb-[3px] font-semibold">
                      Learn More
                    </span>
                    <FaArrowRight className="mt-[1px]" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 z-40 h-full">
            <div className="w-full z-40 lg:w-[80%] pr-8 -mt-8 md:mt-8 md:pr-0 flex justify-center lg:justify-end items-center h-full mx-auto lg:mx-0">
              <div className="relative z-40 h-full w-[260px] md:w-[350px] lg:w-[420px] top-10 md:top-0">
                <div className="h-[300px] md:h-[350px] lg:h-[450px] w-[300px] md:w-[350px] lg:w-[450px] relative z-50 ">
                  <div className="absolute h-full w-full bottom-[-50px] rounded-full right-[-120px] md:right-[-200px] scale-75  bg-[#C7C1FF]"></div>
                  <div className="absolute inset-0 bg-gradient-to-br from-white to-black-900 rounded-full"></div>
                  <img
                    loading="lazy"
                    src={data.image}
                    alt="data"
                    className="h-full absolute inset-0 z-10 bg-white w-full object-cover rounded-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute w-full right-0 left-0 top-[710px] md:top-[690px] lg:top-[550px]">
          <Devider />
        </div>
      </div>
    </>
  );
};

export default HeroEachPage;

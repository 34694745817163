import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import image1 from "../../../assets/images/service1.jpg";
import image2 from "../../../assets/images/service2.jpg";
import image3 from "../../../assets/images/service3.jpg";
import image4 from "../../../assets/images/service4.jpg";
import image5 from "../../../assets/images/service5.jpg";
import image6 from "../../../assets/images/service6.jpg";
import image7 from "../../../assets/images/service7.jpg";
import image8 from "../../../assets/images/service8.jpg";

const AboutBottomContent = () => {
  return (
    <div className="min-h-[500px]  flex my-16 justify-center items-center flex-col lg:flex-row w-[95%] mx-auto">
      <div className="min-h-[400px] w-full lg:w-1/2 -mt-[7rem]">
        <div className="w-full mx-auto md:w-[80%]">
          <h1 className="flex gap-3 font-bold text-2xl lg:text-3xl xl:text-[3rem] justify-center lg:justify-start tracking-wide">
            <span>Our </span> <span className="text-[#FF3D00]">Services</span>
          </h1>
          <p className="lg:text-lg ml-1 mt-3 text-base text-justify md:text-start">
            At Milesway, we support your aspirations of studying or working
            abroad with a comprehensive range of services. Our experienced
            advisors offer personalized guidance, helping you select the right
            destination and navigate visa requirements. We handle all necessary
            paperwork, including visa applications and admission procedures,
            ensuring a smooth process. Our tailored counselling addresses your
            specific needs and concerns, while our pre-departure orientation
            sessions provide essential information about your destination. Upon
            arrival, we assist with finding accommodation, opening bank
            accounts, and connecting with local communities. Let Milesway be
            your trusted partner on this journey. Contact us today to start
            achieving your international goals.
          </p>
          <div className="flex justify-center lg:justify-start items-center mt-4">
            <Link
              className={`bg-[#FF3D00] hover:tracking-wide flex justify-center scale-80 md:scale-100 items-center gap-1.5 text-white px-8 rounded-full hover:text-white duration-150 hover:bg-[#7668FF] relative py-[0.6rem]`}
              to={"/contacts"}
            >
              <span className="md:mb-[3px] font-semibold">Contact Us</span>
              <FaArrowRight className="mt-[1px]" />
            </Link>
          </div>
        </div>
      </div>

      <div className="min-h-[350px] w-full lg:w-1/2 mt-[100px] pl-10 md:pl-0 md:pt-0 md:my-20 flex justify-center items-center">
        <div className="relative h-[350px] w-[300px] scale-85 md:scale-100 md:mr-0 mr-[29px]  -mt-[12rem]">
          <div className="h-[150px] serviceContent w-[150px] bg-black absolute top-0 right-0">
            <img
              loading="lazy"
              src={image1}
              alt="service"
              className="h-full w-full opacity-80 hover:opacity-100 hover:scale-110 duration-150 border shadow-sm object-cover"
            />
          </div>
          <div className="h-[150px] serviceContent w-[150px] bg-black absolute top-[122px] right-[73px]">
            <img
              loading="lazy"
              src={image2}
              alt="service"
              className="h-full w-full opacity-80 hover:opacity-100 hover:scale-110 duration-150 border shadow-sm object-cover"
            />
          </div>
          <div className="h-[150px] serviceContent w-[150px] bg-black absolute top-0 right-[145px]">
            <img
              loading="lazy"
              src={image3}
              alt="service"
              className="h-full w-full opacity-80 hover:opacity-100 hover:scale-110 duration-150 border shadow-sm object-cover"
            />
          </div>
          <div className="h-[150px] serviceContent w-[150px] bg-black absolute top-[125px] right-[-70px]">
            <img
              loading="lazy"
              src={image4}
              alt="service"
              className="h-full w-full opacity-80 hover:opacity-100 hover:scale-110 duration-150 border shadow-sm object-cover"
            />
          </div>
          <div className="h-[150px] serviceContent w-[150px] bg-black absolute top-[244px] right-[4px]">
            <img
              loading="lazy"
              src={image5}
              alt="service"
              className="h-full w-full opacity-80 hover:opacity-100 hover:scale-110 duration-150 border shadow-sm object-cover"
            />
          </div>
          <div className="h-[150px] serviceContent w-[150px] bg-black absolute top-[243px] right-[145px]">
            <img
              loading="lazy"
              src={image6}
              alt="service"
              className="h-full w-full opacity-80 hover:opacity-100 hover:scale-110 duration-150 border shadow-sm object-cover"
            />
          </div>
          <div className="h-[150px] serviceContent w-[150px] bg-black absolute top-[365px] right-[75px]">
            <img
              loading="lazy"
              src={image7}
              alt="service"
              className="h-full w-full opacity-80 hover:opacity-100 hover:scale-110 duration-150 border shadow-sm object-cover"
            />
          </div>
          <div className="h-[150px] serviceContent w-[150px] bg-black absolute top-[366px] right-[217px]">
            <img
              loading="lazy"
              src={image8}
              alt="service"
              className="h-full w-full opacity-80 hover:opacity-100 hover:scale-110 duration-150 border shadow-sm object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBottomContent;

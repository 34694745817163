import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/icons/biglogo.svg";

const Footer = () => {
  return (
    <div>
      <footer className="bg-[#EAF2FF] dark:bg-gray-900 shadow">
        <div className="w-full max-w-screen-xl mx-auto px-4 py-6 lg:py-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-9 gap-8 text-center sm:text-left">
            <div className="md:col-span-3 flex justify-center items-start md:justify-start flex-col">
              <img
                src={Logo}
                alt="Milesway Logo"
                className="w-[270px] sm:mx-0"
              />
              <p className="mt-2 text-gray-900 italic ml-2 dark:text-white font-poppins text-sm">
                We lead dreams into reality
              </p>
              <div className="border-t mt-2">
                <p className="mt-2 text-gray-900 italic ml-2 dark:text-white font-poppins text-xs">
                  +977 9851339696 , +977 9851225506
                </p>
                <p className="text-gray-900 italic ml-2 dark:text-white font-poppins text-xs">
                  info@mileswayedu.com
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <h2 className="mb-3 text-base font-poppins font-semibold text-gray-900 uppercase dark:text-white">
                Milesway
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4 text-[0.8rem] font-poppins">
                  <Link to="/about-us" className="hover:underline">
                    About Us
                  </Link>
                </li>
                <li className="mb-4 text-[0.8rem] font-poppins">
                  <Link to="/study-abroad" className="hover:underline">
                    Study Abroad
                  </Link>
                </li>
                <li className="mb-4 text-[0.8rem] font-poppins">
                  <Link to="/internship-abroad" className="hover:underline">
                    Internship Abroad
                  </Link>
                </li>
              </ul>
            </div>
            <div className="md:col-span-2">
              <h2 className="mb-3 text-base font-poppins font-semibold text-gray-900 uppercase dark:text-white">
                Help Center
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4 text-[0.8rem] font-poppins">
                  <Link to="/" className="hover:underline">
                    Instagram
                  </Link>
                </li>
                <li className="mb-4 text-[0.8rem] font-poppins">
                  <Link to="/" className="hover:underline">
                    Facebook
                  </Link>
                </li>
                <li className="mb-4 text-[0.8rem] font-poppins">
                  <Link to="/contacts" className="hover:underline">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="md:col-span-2">
              <h2 className="mb-3 text-base font-poppins font-semibold text-gray-900 uppercase dark:text-white">
                Study/Internship
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4 text-[0.8rem] font-poppins">
                  <Link to="/study-abroad" className="hover:underline">
                    Study in Abroad
                  </Link>
                </li>
                <li className="mb-4 text-[0.8rem] font-poppins">
                  <Link to="/internship-abroad" className="hover:underline">
                    Internship in Abroad
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bg-[#C7C1FF] py-4">
          <div className="max-w-screen-xl mx-auto flex justify-center items-center">
            <span className="text-[0.8rem] font-poppins text-black tracking-wide text-center">
              Copyright © {new Date().getFullYear()}{" "}
              <Link to="/"> Milesway</Link> | All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

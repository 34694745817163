import React from "react";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookSquare } from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const ContactDetails = () => {
  return (
    <>
      <div className="mt-[90px] md:w-[80%] w-full float-right flex flex-col gap-4">
        <h3 className="text-xs font-poppins uppercase text-black font-semibold">
          Contact Us:
        </h3>
        <h2 className=" font-semibold text-black/90 text-2xl md:text-3xl">
          <span className="text-[#FF3D00] font-semibold text-2xl md:text-3xl mr-2">
            Milesway:
          </span>
          Empowering Your Dreams Abroad with Expert Guidance and Full Support.
        </h2>
        <p className="text-sm font-poppins text-black/80">
          Send us a message and we'll get your questions answered as soon as
          possible.
        </p>
        <div>
          <button className="border px-7 py-3 scale-80 md:scale-100 ml-[-15px] md:ml-0 mt-[-5px] rounded-full md:mt-2 gap-[2px] hover:cursor-pointer border-[#FF3D00] text-[#FF3D00] flex items-center hover:bg-[#FF3D00] duration-200 hover:text-slate-100 font-semibold font-poppins text-sm">
            <IoCallSharp className="text-base" />
            <span>+977 9851339696</span>
          </button>
        </div>
        <div className="mt-16 flex flex-row md:flex-col gap-7">
          <div className="font-poppins flex flex-col gap-2 mt-7">
            <h3 className="text-black font-poppins font-semibold tracking-wider uppercase">
              Contact us through
            </h3>
            <div className="flex flex-col gap-1 px-1">
              <p className="text-sm flex gap-1 items-center text-black/80">
                <MdEmail />
                info@mileswayedu.com
              </p>

              <p className="text-sm flex gap-1 items-center text-black/80">
                <IoCallSharp />
                +977 9851339696 , +977 9851225506
              </p>
            </div>
          </div>
          <div>
            <h3 className="text-black font-poppins mt-7 md:mt-0 font-semibold tracking-wider uppercase">
              Social media
            </h3>
            <div className="flex gap-6 mt-2">
              <Link to={"/"}>
                <FaFacebookSquare className="text-primary-color scale-125 text-xl" />
              </Link>
              <Link to={"/"}>
                <RiInstagramFill className="text-primary-color text-xl scale-[1.35]" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Input,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import { IoArrowForwardOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { API_BASE_URL } from "../../../redux/config";

const motives = [
  { id: 1, name: "Study" },
  { id: 2, name: "Internship" },
];

const ContactForm = ({
  formSubmit,
  contactData,
  handelChange,
  validationErrors,
  setContactData,
  enqueryData,
  setEnqueryData,
}) => {
  const [countries, setCountries] = useState([]);
  const [university, setUniversity] = useState([]);
  const [internPlace, setInternPlace] = useState([]);
  const location = useLocation();

  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    setContactData((prev) => ({
      ...prev,
      country: enqueryData.country,
      motive: enqueryData.isUniversity,
      isUniversity: enqueryData.isUniversity,
      university: enqueryData.university,
    }));
  }, [setContactData, enqueryData]);

  useEffect(() => {
    // Fetch data and set the initial state
    const fetchData = async (url, setter) => {
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          setter(data);

          // Map country name to ID
          const country = data.find(
            (c) =>
              c?.name?.toLowerCase() === location?.state?.country?.toLowerCase()
          );
          if (country) {
            setEnqueryData((prev) => ({
              ...prev,
              isUniversity: location?.state?.isUniversity ? "1" : "2",
              university: location.state.university || "",
              country: country.id,
            }));
          }
        } else {
          console.error(`Failed to fetch from ${url}`);
        }
      } catch (error) {
        console.error(`Error fetching from ${url}:`, error);
      }
    };

    fetchData(`${API_BASE_URL}/api/countries/countries`, setCountries);
    fetchData(`${API_BASE_URL}/api/university/university`, setUniversity);
    fetchData(`${API_BASE_URL}/api/work/work`, setInternPlace);
  }, [location.state, setEnqueryData]);

  useEffect(() => {
    // Filter universities or internship places based on selected country and motive
    const updateFilteredOptions = () => {
      console.log(contactData.country);
      if (contactData.country) {
        const finalOptions =
          enqueryData.isUniversity === "2"
            ? internPlace.filter(
                (place) =>
                  Number(place.countryid) === Number(contactData.country)
              )
            : university.filter(
                (uni) => Number(uni.countryid) === Number(contactData.country)
              );
        setFilteredOptions(finalOptions);
      } else {
        setFilteredOptions([]);
      }
    };

    updateFilteredOptions();
  }, [
    contactData.country,
    enqueryData.isUniversity,
    university,
    internPlace,
    location?.state?.country,
  ]);

  const handleSelectChange = (name, value) => {
    setEnqueryData((prev) => ({ ...prev, [name]: value }));
    setContactData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <div className="col-span-1 min-h-[400px] flex justify-center items-center mt-[100px] z-10">
        <form
          onSubmit={formSubmit}
          className="w-full md:w-[92%] px-0 max-w-[500px]"
        >
          <Card className="rounded">
            <div className="font-poppins rounded bg-white border border-black/10 shadow h-full p-4 md:p-6 flex gap-3 flex-col">
              <h2 className="font-semibold uppercase text-black/80">
                Contact Form
              </h2>
              <Input
                name="name"
                size="sm"
                className="rounded"
                variant="faded"
                type="text"
                label="Your Name"
                value={contactData.name}
                onChange={handelChange}
              />
              {validationErrors?.name && (
                <span className="errorFront !mt-[-11px]">
                  {validationErrors.name}
                </span>
              )}
              <Input
                name="email"
                size="sm"
                variant="faded"
                type="email"
                label="Your email"
                value={contactData.email}
                onChange={handelChange}
                errorMessage={validationErrors.email}
              />
              {validationErrors?.email && (
                <span className="errorFront !mt-[-16px]">
                  {validationErrors.email}
                </span>
              )}
              <Input
                name="phone"
                size="sm"
                variant="faded"
                type="number"
                label="Your Phone Number"
                value={contactData.phone}
                onChange={handelChange}
                errorMessage={validationErrors.phone}
              />
              {validationErrors?.phone && (
                <span className="errorFront !mt-[-16px]">
                  {validationErrors.phone}
                </span>
              )}
              <Select
                items={countries}
                label="Select Country"
                className="rounded w-full"
                variant="faded"
                size="sm"
                name="country"
                selectedKeys={[String(enqueryData.country) || ""]}
                onChange={(key) =>
                  handleSelectChange("country", key.target.value)
                }
              >
                {(country) => (
                  <SelectItem key={country.id} value={country.id}>
                    {country.name}
                  </SelectItem>
                )}
              </Select>
              {validationErrors?.country && (
                <span className="errorFront !mt-[-8px]">
                  {validationErrors.country}
                </span>
              )}
              <Select
                items={motives}
                label="Select Motive"
                className="rounded w-full"
                variant="faded"
                size="sm"
                name="motive"
                selectedKeys={[enqueryData.isUniversity]}
                onChange={(key) => {
                  const selectedMotive = key.target.value;
                  handleSelectChange("isUniversity", selectedMotive);
                  setContactData((prev) => ({
                    ...prev,
                    isUniversity: selectedMotive === "1" ? "1" : "2", // Ensure isUniversity is set correctly
                    university: "", // Reset university selection if motive changes
                  }));
                }}
              >
                {(motive) => (
                  <SelectItem key={motive.id} value={String(motive.id)}>
                    {motive.name}
                  </SelectItem>
                )}
              </Select>
              {String(enqueryData.isUniversity).trim().length > 0 && (
                <>
                  <Select
                    items={filteredOptions}
                    label={`Select ${String(enqueryData.isUniversity) === "2" ? "Company" : "University"}`}
                    className="rounded w-full"
                    variant="faded"
                    size="sm"
                    name="motive"
                    selectedKeys={[String(enqueryData.university)]}
                    onChange={(key) =>
                      handleSelectChange("university", key.target.value)
                    }
                  >
                    {(option) => (
                      <SelectItem key={option.id} value={String(option.id)}>
                        {option.name}
                      </SelectItem>
                    )}
                  </Select>
                  {validationErrors?.university && (
                    <span className="errorFront !mt-[-8px]">
                      {validationErrors.university}
                    </span>
                  )}
                </>
              )}
              <Textarea
                name="msg"
                variant="faded"
                minRows={3}
                label="Message"
                value={contactData.msg}
                onChange={handelChange}
                errorMessage={validationErrors.msg}
              />
              {validationErrors?.msg && (
                <span className="errorFront !mt-[-14px]">
                  {validationErrors.msg}
                </span>
              )}
              <Button
                type="submit"
                color="primary"
                radius="full"
                endContent={<IoArrowForwardOutline />}
                variant="flat"
                className="normal-case"
              >
                Send Message
              </Button>
            </div>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

// App.js

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import makeStore from "./redux/store";
import PageUser from "./User/PageUser";
import PageDashboard from "./Dashboard/PageDashboard";
import UserState from "./context/userState";
import { Toaster } from "sonner";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <React.Fragment>
      <Provider store={makeStore()}>
        <GoogleOAuthProvider>
          <BrowserRouter>
            <UserState>
              <PageUser />
              <PageDashboard />
              <Toaster richColors position="top-right" closeButton />
            </UserState>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </Provider>
    </React.Fragment>
  );
}

export default App;

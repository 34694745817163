import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { reviewData } from "../assets/ReviewsData";

const Review = ({ reviews }) => {
  const [reviewsDataFinal, setReviewsDataFinal] = useState([]);
  useEffect(() => {
    if (reviews) {
      setReviewsDataFinal(reviews);
    } else {
      setReviewsDataFinal(reviewData);
    }
  }, [reviews]);
  return (
    <div id="reviewes" className="h-auto py-10">
      <section className="bg-[#EAF2FF] px-4 py-10">
        <div className="max-w-screen-xl mx-auto">
          <h1 className="flex gap-2 font-bold text-2xl lg:text-3xl xl:text-[3rem] justify-center items-center mb-3 md:mb-10">
            <span className="text-[#FF3D00]">Feedback</span>
            <span>Summary</span>
          </h1>
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={10}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            loop={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
          >
            {reviewsDataFinal.map((e, index) => (
              <SwiperSlide key={index}>
                <div className="flex justify-center pb-16 pt-5">
                  <div className="bg-white rounded shadow-md p-8 text-center flex flex-col gap-2 justify-center items-center w-[400px]">
                    <p className="font-bold text-xl uppercase line-clamp-1">
                      {e.name}
                    </p>
                    <p className="text-base text-gray-700 line-clamp-3">
                      {e.description}
                    </p>
                    <div className="flex flex-col items-center w-full mt-1">
                      <Rating name="simple-controlled" value={e.rating} />
                      <div className="text-gray-700 mt-2">
                        Date: {e.date.split("T")[0]}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default Review;

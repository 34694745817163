import Lottie from "lottie-react";
import React, { useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import introduction from "../../../assets/lotifile/introduction.json";
import introductionImg from "../../../assets/images/introduction.jpg";

const LottieAnimation = ({ animationData }) => {
  const animationRef = useRef();

  const handleComplete = () => {
    setTimeout(() => {
      animationRef.current.goToAndPlay(0);
    }, 2000);
  };

  return (
    <Lottie
      lottieRef={animationRef}
      animationData={animationData}
      loop={false}
      onComplete={handleComplete}
    />
  );
};

const Introduction = () => {
  return (
    <div className="p-2 py-6 md:p-10 h-full w-full bg-[#EAF2FF]">
      <div className=" flex justify-center flex-col-reverse md:flex-row items-center gap-16">
        <div className="w-full md:w-[40%] flex justify-center">
          <div className="h-[260px] w-[260px] lg:h-[350px] lg:w-[350px] overflow-hidden md:overflow-visible rounded-full bg-white relative flex justify-center items-center">
            <div className="absolute inset-0 h-full w-full scale-[2.1]">
              <LottieAnimation animationData={introduction} />
            </div>
            <div className="absolute inset-0 h-full w-full rounded-full scale-[1.01] overflow-hidden">
              <img
                loading="lazy"
                src={introductionImg}
                alt="introduction"
                className="h-full w-full object-cover rounded-full"
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <div className="flex flex-col items-center md:items-start gap-4 w-[99%] mx-auto md:w-[90%]">
            <h1 className="flex gap-3 font-bold text-2xl lg:text-3xl xl:text-[3rem] tracking-wide">
              <span>Why</span> <span className="text-[#FF3D00]">Milesway?</span>
            </h1>
            <p className="text-base lg:text-lg ml-1 text-center md:text-left">
              Choosing Milesway means entrusting your dreams of studying or
              working abroad to dedicated professionals who prioritize your
              success. We offer comprehensive services—from expert guidance and
              efficient processing to personalized counselling—ensuring every
              aspect of your journey is carefully managed. With our support,
              confidently navigate international relocation and achieve your
              goals. Let Milesway be your partner in making dreams a reality.
            </p>
            <div className="flex">
              <Link
                className={`bg-[#FF3D00] hover:tracking-wide flex scale-80 md:scale-100 justify-center items-center gap-1.5 text-white px-8 rounded-full hover:text-white duration-150 hover:bg-[#7668FF] relative py-[0.6rem]`}
                to={"/"}
              >
                <span className="md:mb-[3px] font-semibold">Learn More</span>
                <FaArrowRight className="mt-[1px]" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;

import React from "react";

const Vision = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 35 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.0469 12.5375C24.5 10.625 22.9688 9.03127 20.8906 8.60627C19.5781 8.28752 18.1562 8.50002 17.0625 9.13752C16.4062 9.56252 15.75 9.45627 15.2031 9.35002C15.0938 9.35002 14.875 9.24377 14.6562 9.24377L5.90625 7.86252C5.03125 7.75627 4.04688 7.96877 3.28125 8.60627C2.625 9.13752 2.1875 9.98752 2.1875 10.8375V17.1063C2.1875 17.9563 2.625 18.8063 3.28125 19.3375C3.9375 19.8688 4.70312 20.1875 5.46875 20.1875C5.6875 20.1875 5.79688 20.1875 6.01562 20.1875L14.7656 18.8063C14.9844 18.8063 15.0938 18.7 15.3125 18.7C16.1875 18.3813 16.9531 18.4875 17.7188 18.7C18.375 19.0188 19.0312 19.125 19.7969 19.125C21.4375 19.125 23.0781 18.3813 24.0625 17.1063C25.0469 15.8313 25.375 14.2375 25.0469 12.5375ZM19.6875 15.9375C18.4844 15.9375 17.5 14.9813 17.5 13.8125C17.5 12.6438 18.4844 11.6875 19.6875 11.6875C20.8906 11.6875 21.875 12.6438 21.875 13.8125C21.875 14.9813 20.8906 15.9375 19.6875 15.9375Z"
          fill="black"
        />
        <path
          d="M20.0157 2.12501C16.297 2.01876 12.9064 3.71876 10.8282 6.48126L14.9845 7.11876C15.3126 7.11876 15.5314 7.22501 15.8595 7.33126C15.9689 7.33126 15.9689 7.33126 15.9689 7.33126C17.5001 6.48126 19.4689 6.16251 21.3282 6.58751C24.172 7.22501 26.4689 9.35001 27.1251 12.1125C27.672 14.3438 27.1251 16.6813 25.7032 18.4875C24.172 20.1875 21.9845 21.25 19.6876 21.25C18.7032 21.25 17.7189 21.0375 16.7345 20.7188C16.5157 20.6125 16.0782 20.7188 15.8595 20.7188C15.6407 20.825 15.3126 20.825 15.0939 20.9313L8.09387 21.9938L7.9845 22.95C7.87512 24.3313 8.53137 25.7125 9.62512 26.5625C10.7189 27.4125 12.2501 27.7313 13.5626 27.3063C13.672 27.3063 13.672 27.3063 13.7814 27.3063C13.8907 27.3063 13.8907 27.4125 13.8907 27.5188L13.1251 31.6625C13.1251 31.9813 13.1251 32.3 13.3439 32.5125C13.5626 32.725 13.8907 32.9375 14.2189 32.9375H27.3439C27.672 32.9375 28.0001 32.8313 28.2189 32.5125C28.4376 32.3 28.4376 31.875 28.4376 31.5563L27.3439 27.5188C26.4689 24.5438 27.1251 21.25 28.9845 18.3813C30.0782 16.6813 30.7345 14.6625 30.6251 12.6438C30.5157 7.01251 25.8126 2.33751 20.0157 2.12501Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export default Vision;

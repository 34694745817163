import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { postContacts } from "../../../redux/slices/messageSlice";
import { toast } from "sonner";
import ContactForm from "./ContactForm";
import ContactDetails from "./ContactDetails";

const Contact = () => {
  const dispatch = useDispatch();

  const scrollUP = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [enqueryData, setEnqueryData] = useState({
    isUniversity: "",
    country: "",
    university: "",
  });
  useEffect(() => {
    scrollUP();
  }, []);

  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    motive: "",
    isUniversity: "",
    university: "",
    msg: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const handelChange = (e) => {
    const { name, value } = e.target;

    if (name === "motive") {
      setContactData({
        ...contactData,
        [name]: value,
        isUniversity: value === "1" ? "1" : "2",
        university: "",
      });
    } else {
      setContactData({ ...contactData, [name]: value });
    }
  };

  const handelPostContacts = async (data) => {
    try {
      const response = await dispatch(postContacts(data));
      if (response.payload?.success) {
        toast.success("Message sent successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot add Message");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationErrors = {
      name: "",
      email: "",
      phone: "",
      country: "",
      motive: "",
      msg: "",
    };

    if (!contactData.name.trim()) {
      newValidationErrors.name = "Name is required.";
      isValid = false;
    }
    if (!contactData.email.trim()) {
      newValidationErrors.email = "Email is required.";
      isValid = false;
    }
    if (!contactData.phone.trim()) {
      newValidationErrors.phone = "Phone number is required.";
      isValid = false;
    }
    if (contactData.phone.trim().length !== 10) {
      newValidationErrors.phone = "Phone number must be 10 characters.";
      isValid = false;
    }
    if (!String(contactData.country).trim()) {
      newValidationErrors.country = "Preferred country is required.";
      isValid = false;
    }
    if (!String(contactData.motive).trim()) {
      newValidationErrors.motive = "Motive is required.";
      isValid = false;
    }
    if (contactData.motive === "Study" && !contactData.university.trim()) {
      newValidationErrors.university = "University selection is required.";
      isValid = false;
    }
    if (contactData.msg.trim().length < 10) {
      newValidationErrors.msg = "Message must be at least 10 characters long.";
      isValid = false;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  const formSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (!contactData.isUniversity || contactData.isUniversity.trim() === "") {
        setValidationErrors({
          ...validationErrors,
          motive: "Please select a valid motive.",
        });
        return;
      }
      handelPostContacts(contactData);
      setContactData({
        name: "",
        email: "",
        phone: "",
        country: "",
        motive: "",
        university: "",
        isUniversity: "",
        msg: "",
      });
      setEnqueryData({ isUniversity: "", country: "", university: "" });
    }
  };

  return (
    <div className="min-h-screen px-2 md:px-5 pt-[55px] mb-10">
      <div className="relative h-full w-full">
        <div className="absolute top-0 h-[400px] rounded-b-md w-full border border-t-0 bg-[#EAF3FF] border-black/10 bg-opacity-1"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-10">
          <div className="col-span-1 min-h-[400px] z-10 md:px-5 px-2">
            <ContactDetails />
          </div>
          <ContactForm
            formSubmit={formSubmit}
            contactData={contactData}
            handelChange={handelChange}
            validationErrors={validationErrors}
            setContactData={setContactData}
            setEnqueryData={setEnqueryData}
            enqueryData={enqueryData}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;

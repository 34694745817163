import React, { useEffect, useState } from "react";
import TablePage from "./Table";
import { useDispatch, useSelector } from "react-redux";
import {
  getreviews,
  postreview,
  deletereview,
} from "../../../../redux/slices/reviewsSlice";
import { toast } from "sonner";

const Page = (props) => {
  const { MsgId, isDashboard } = props;
  const dispatch = useDispatch();
  const [finalData, setFinalData] = useState([]);
  const { reviewData } = useSelector((state) => state.reviewReducer);
  const [showFront, setShowFront] = useState(false);

  useEffect(() => {
    dispatch(getreviews());
  }, [dispatch, showFront]);

  useEffect(() => {
    let finalCategoryData = reviewData.map((item, index) => ({
      ...item,
      sn: index + 1,
    }));
    setFinalData(finalCategoryData);
  }, [reviewData]);

  const handelpost = async (data) => {
    try {
      const response = await dispatch(postreview(data));
      if (response.payload?.success) {
        toast.success("Send review successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot send review");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelDelete = async (data) => {
    try {
      const response = await dispatch(deletereview(data));
      if (response.meta?.requestStatus === "fulfilled") {
        toast.warning("review deleted successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot delete review");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  return (
    <>
      <TablePage
        contentData={finalData[0]?.sn ? finalData : []}
        handelPost={handelpost}
        handelDelete={handelDelete}
        MsgId={MsgId}
        isDashboard={isDashboard}
        showFront={showFront}
        setShowFront={setShowFront}
      />
    </>
  );
};

export default Page;

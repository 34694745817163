"use client";
import React from "react";
import Register from "./Register";

const Page = () => {
  return (
    <div>
      <Register />
    </div>
  );
};

export default Page;

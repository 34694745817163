import { Button } from "@nextui-org/react";
import { Link } from "react-router-dom";

import React, { useEffect, useRef, useState, useCallback } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { toast } from "sonner";
import axios from "axios";
import { API_BASE_URL } from "../../../redux/config";

const OTPForm = ({ handelRegister }) => {
  const linkBtnRef = useRef(null);
  const firstInputRef = useRef(null);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const email = localStorage.getItem("email");
      const otpCode = otp.join("");

      if (!otpCode || otpCode.length < 6) {
        toast.warning("Please enter the complete OTP.");
        return;
      }

      try {
        setIsLoading(true);
        const response = await axios.post(
          `${API_BASE_URL}/api/user/verify/verify-otp`,
          { email, otp: otpCode }
        );

        if (response.data.success) {
          toast.success(response.data.message);
          if (handelRegister) {
            handelRegister();
          } else {
            toast.error("Cannot register User");
          }
        } else {
          toast.error(response.data.message || "Invalid OTP.");
        }
      } catch (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message ||
            "An error occurred. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    },
    [otp, handelRegister]
  );

  useEffect(() => {
    const inputs = document.querySelectorAll('input[type="text"]');
    const handleKeyDown = (e) => {
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      const index = Array.from(inputs).indexOf(e.target);

      if (e.key === "Delete" || e.key === "Backspace") {
        if (index > 0 && e.target.value === "") {
          inputs[index - 1].focus();
        } else if (index === otp.length - 1 && e.target.value !== "") {
          const newOtp = [...otp];
          newOtp[index] = "";
          setOtp(newOtp);
        }
      }

      if (e.key === "Enter") {
        handleSubmit(e);
      }
    };

    inputs.forEach((input) => {
      input.addEventListener("keydown", handleKeyDown);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("keydown", handleKeyDown);
      });
    };
  }, [otp, handleSubmit]);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-center bg-slate-50 overflow-hidden">
      <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
        <div className="flex justify-center">
          <div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
            <header className="mb-8">
              <h1 className="text-2xl font-bold mb-1">Email Verification</h1>
              <p className="text-[15px] text-slate-500">
                Enter the 6-digit verification code that was sent to your email
                account.
              </p>
            </header>
            <form id="otp-form" onSubmit={handleSubmit}>
              <div className="flex items-center justify-between gap-3">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    className="w-12 h-12 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-slate-300 hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    ref={index === 0 ? firstInputRef : null}
                    disabled={isLoading}
                  />
                ))}
              </div>
              <div className="max-w-full mx-auto mt-6">
                <Button
                  color="primary"
                  type="submit"
                  endContent={<FaArrowRight className="mt-[3px]" />}
                  className="w-full hover:tracking-wider duration-200"
                  radius="sm"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Verify OTP
                </Button>
                <Link
                  to="/auth/resetpassword"
                  className="hidden"
                  ref={linkBtnRef}
                >
                  Reset password
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPForm;

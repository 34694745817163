import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  registerUser,
  registerUserAvatar,
} from "../../../redux/slices/authSlice.js";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import Form from "./Form.js";
import AvatarPage from "./Avatar.js";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [isChooseImage, setIsChooseImage] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const [formRegisterData, setFormRegisterData] = useState({
    email: "",
    password: "",
    name: "",
    image: "",
  });

  const formInputFieldChange = (e) => {
    if (e.target.name === "image") {
      setFormRegisterData({ ...formRegisterData, image: e.target.files[0] });
    } else {
      setFormRegisterData({
        ...formRegisterData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validateForm = () => {
    const { email, password, name } = formRegisterData;
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    if (!name) {
      newErrors.name = "Name is required";
    } else if (name.length < 3) {
      newErrors.name = "Name must be at least 3 characters";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handelRegister = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (!formRegisterData.image) {
      setIsChooseImage(true);
      return;
    }
    try {
      let response;
      if (formRegisterData.image instanceof File) {
        response = await dispatch(registerUser(formRegisterData));
      } else {
        response = await dispatch(registerUserAvatar(formRegisterData));
      }

      if (response.error) {
        if (response.error.message) {
          toast.error("User with this email already exists");
        } else if (
          response.error.code &&
          response.error.code.includes("ERR_BAD_REQUEST")
        ) {
          toast.error("Invalid email or password");
        }
        return;
      }

      if (response.payload.token) {
        localStorage.setItem("token", JSON.stringify(response.payload.token));
        setFormRegisterData({
          email: "",
          password: "",
          name: "",
          image: "",
        });
        setIsChooseImage(false);
        navigate("/dashboard");
        window.location.reload();
        toast.success("User registered successfully");
      }

      if (response.payload?.error) {
        toast.error(response.payload.error);
      }
    } catch (error) {
      toast.error("Some error occurred, try again later");
    }
  };

  const setSelectedImage = (image) => {
    setFormRegisterData({ ...formRegisterData, image });
  };

  return (
    <>
      {!isChooseImage ? (
        <div className="h-[100vh] gap-2 flex flex-col justify-center items-center">
          <Form
            toggleVisibility={toggleVisibility}
            handelRegister={handelRegister}
            formInputFieldChange={formInputFieldChange}
            formRegisterData={formRegisterData}
            isVisible={isVisible}
            setIsChooseImage={setIsChooseImage}
            errors={errors}
          />
        </div>
      ) : (
        <AvatarPage
          setIsChooseImage={setIsChooseImage}
          setSelectedImage={setSelectedImage}
          handelRegister={handelRegister}
          formRegisterData={formRegisterData}
        />
      )}
    </>
  );
};

export default Register;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import EachUnivertisy from "./EachUnivertisy";

const UniversitiesSlider = ({ universities, isUniversity }) => {
  return (
    <div id="containerUniversity">
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 relative">
        <Swiper
          modules={[Pagination, Autoplay, Navigation]}
          spaceBetween={10}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          loop={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          className="w-[100%]"
        >
          {universities.map((e) => {
            return (
              <SwiperSlide key={e.id}>
                <EachUnivertisy data={e} isUniversity={isUniversity} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default UniversitiesSlider;

import React, { useState } from "react";
import { IoArrowForwardOutline } from "react-icons/io5";
import { Input, Button, Card } from "@nextui-org/react";
import { EyeSlashFilledIcon } from "../../../assets/jsx/EyeSlashFilledIcon.js";
import { MailIcon } from "../../../assets/jsx/MailIcon.js";
import Logo from "../../../assets/icons/biglogo.svg";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/slices/authSlice.js";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { EyeFilledIcon } from "../../../assets/jsx/EyeFilledIcon.js";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [userFormData, setUserFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const { email, password } = userFormData;
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handelLogin = async (data) => {
    try {
      const response = await dispatch(loginUser(data));

      if (response.error) {
        if (
          response.error.code &&
          response.error.code.includes("ERR_BAD_REQUEST")
        ) {
          toast.error("Invalid email or password");
        }
        return;
      }

      if (response.payload.token) {
        localStorage.setItem("token", JSON.stringify(response.payload.token));
        navigate("/dashboard");
        window.location.reload();
        toast.success("User logged in successfully.");
      } else if (response.payload?.error) {
        toast.error(response.payload.error);
      }
    } catch (error) {
      toast.error("Some error occurred, try again later");
    }
  };

  const formInputFieldChange = (e) => {
    setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
  };

  const formsubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handelLogin(userFormData);
    }
  };

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <div className="min-h-[100vh] flex justify-center items-center bg-[#eaf2ffac]">
      <div className="min-h-[200px] w-[400px] my-20 rounded ">
        <form onSubmit={formsubmit}>
          <Card
            radius="none"
            className="rounded-sm flex gap-5 flex-col p-8 font-poppins"
          >
            <div className="flex justify-between items-center">
              <h3 className="text-black font-semibold tracking-wider text-2xl font-Orbitron">
                Sign In
              </h3>
              <img src={Logo} height={40} className="w-[160px]" alt="logo" />
            </div>
            <div className="flex gap-1 -mt-1 flex-col">
              <label
                htmlFor="email"
                className="text-sm text-black cursor-pointer"
              >
                Email
              </label>
              <Input
                size="sm"
                variant="faded"
                radius="sm"
                label="Email"
                type="text"
                name="email"
                value={userFormData.email}
                onChange={formInputFieldChange}
                endContent={
                  <MailIcon className="text-2xl mb-1 text-default-400 pointer-events-none flex-shrink-0" />
                }
              />
              {errors?.email?.length > 1 && (
                <div className="text-xs -mb-2 font-poppins text-red-700 ml-2">
                  {errors.email}
                </div>
              )}
            </div>
            <div className="flex gap-1 flex-col">
              <div className="flex justify-between">
                <label
                  htmlFor="password"
                  className="text-sm text-black cursor-pointer"
                >
                  Password
                </label>
              </div>
              <Input
                size="sm"
                variant="faded"
                label="Password"
                radius="sm"
                name="password"
                value={userFormData.password}
                onChange={formInputFieldChange}
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                  >
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl mb-1 text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl mb-1 text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                className="w-full"
              />
              {errors?.password?.length > 1 && (
                <div className="text-xs -mb-2 font-poppins text-red-700 ml-2">
                  {errors.password}
                </div>
              )}
            </div>
            <div className="flex gap-2 justify-end">
              <Button
                fullWidth
                color="primary"
                radius="none"
                className="rounded-sm"
                type="submit"
                endContent={<IoArrowForwardOutline />}
              >
                Login
              </Button>
            </div>
            <div>
              <h3 className="text-black/80 text-sm text-center">
                Welcome back to Milesway!!!
              </h3>
            </div>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default Login;

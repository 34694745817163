import axios from "axios";
import { API_BASE_URL } from "../redux/config";

const FetchSingleUserToken = async (token) => {
  try {
    const config = {
      headers: {
        "auth-token": token,
      },
    };
    const response = await axios.get(`${API_BASE_URL}/api/user/mydata`, config);
    return response?.data[0] || null;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export default FetchSingleUserToken;

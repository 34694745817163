import React, { useEffect, useState } from "react";
import UserContext from "./userContext";
import FetchSingleUserToken from "./FetchSingleUserToken";

const UserState = ({ children }) => {
  const [userData, setUserData] = useState({
    isLogin: false,
    data: {},
    token: "",
  });

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      setUserData({ data: null, isLogin: false, token: null });
    } else {
      const fetchData = async () => {
        const resData = await FetchSingleUserToken(token);
        setUserData({ data: resData, isLogin: true, token });
      };
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <UserContext.Provider
      value={{
        userData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserState;

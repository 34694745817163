import React from "react";
import Devider from "../Home/Devider";
import { BreadcrumbItem, Breadcrumbs } from "@nextui-org/react";
import { TiHome } from "react-icons/ti";
import { Link } from "react-router-dom";
import { FaGraduationCap } from "react-icons/fa6";
import CountryCards from "./CountryCards";
import { MdOutlineWork } from "react-icons/md";
import { Spinner } from "@nextui-org/react"; // Import Spinner from Next UI

const StudyAbroad = ({ isStudy, countries, loading }) => {
  return (
    <div className="min-h-[300px] pt-[55px] relative">
      <div className="absolute w-full right-0 left-0 top-[60px]">
        <Devider />
      </div>
      <div className="pt-[50px]">
        <div className="flex justify-center items-center flex-col gap-1">
          <h1 className="flex gap-3 font-bold text-2xl md:text-3xl">
            {isStudy ? (
              <span className="text-[#7668FF]">Study </span>
            ) : (
              <span className="text-[#FF3D00]">Internship </span>
            )}
            <span>Abroad</span>
          </h1>
          <div className="z-30 ">
            <Breadcrumbs size="lg">
              <BreadcrumbItem
                className="!hover:text-black"
                startContent={<TiHome />}
              >
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              {isStudy ? (
                <BreadcrumbItem startContent={<FaGraduationCap />}>
                  Study Abroad
                </BreadcrumbItem>
              ) : (
                <BreadcrumbItem startContent={<MdOutlineWork />}>
                  Internship Abroad
                </BreadcrumbItem>
              )}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-[300px]">
          <Spinner size="lg" />
        </div>
      ) : (
        <div className="w-[90%] mx-auto my-16">
          <CountryCards countries={countries} isStudy={isStudy} />
        </div>
      )}
    </div>
  );
};

export default StudyAbroad;

import React from "react";
import UniversitiesSlider from "./UniversitiesSlider";

const EachUnivertityPage = ({ data, isUniversity }) => {
  return (
    <div className="md:w-full w-[90%] mx-auto">
      <h3 className="flex gap-3 font-bold text-xl mb-5">
        <span>{isUniversity ? "Universities" : "Internship"} </span>
        in <span className="text-[#FF3D00]">{data.country_name}</span>
      </h3>
      <div>
        <UniversitiesSlider
          isUniversity={isUniversity}
          universities={data.data || []}
        />
      </div>
    </div>
  );
};

export default EachUnivertityPage;

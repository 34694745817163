import React, { useEffect, useState } from "react";
import HeroSection from "./HeroSection";
import Slogan from "./Slogan";
import IntroCards from "./IntroCards";
import Introduction from "./Introduction";
import ContactInfoPage from "./ContactInfoPage";
import Services from "../About/Services";
import Review from "./Review";
import Feedback from "./Feedback";
import { API_BASE_URL } from "../../../redux/config";
import axios from "axios";

const Home = () => {
  const scrollUP = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollUP();
  }, []);

  const fetchTotalUsers = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/banners/banner/active`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching total users:", error);
      return null;
    }
  };

  const fetchTotalReviews = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/review/review/front`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching total reviews:", error);
      return null;
    }
  };

  const [carouselData, setCarouselData] = useState([]);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const usersData = await fetchTotalUsers();
      setCarouselData(usersData);

      const reviewsData = await fetchTotalReviews();
      setReviews(reviewsData);
    };
    fetchData();
  }, []);

  return (
    <div>
      <HeroSection carData={carouselData} />
      <Slogan />
      <IntroCards />
      <Introduction />
      <div className="md:mt-0 md:mb-0 mt-[60px] mb-[150px]">
        <ContactInfoPage />
      </div>
      <Services />
      <Review reviews={reviews?.length > 3 ? reviews : null} />
      <Feedback />
    </div>
  );
};

export default Home;

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Dashboard from "./Pages/dashboard/page";
import TopNav from "./common/Navigation/TopNav";
import Message from "./Pages/activities/message/Page";
import Users from "./Pages/activities/users/Page";
import Reviews from "./Pages/contents/review/Page";
import Banner from "./Pages/contents/banner/Page";
import Countries from "./Pages/contents/countries/Page";
import University from "./Pages/studyworks/university/Page";
import Work from "./Pages/studyworks/work/Page";
import EachMsg from "./Pages/activities/message/EachMsg/EachMsg";
import Register from "../Dashboard/common/Register/Register";
import UserContext from "../context/userContext";
import { useDispatch, useSelector } from "react-redux";
import { getUsersNumber } from "../redux/slices/authSlice";

const PageDashboard = ({ setProgress }) => {
  const [hamClick, setHamClick] = useState(false);
  const Location = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useContext(UserContext);
  const { totalUser } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (Location.includes("/dashboard")) {
      dispatch(getUsersNumber());
    }
  }, [dispatch, Location]);

  useEffect(() => {
    if (Location.includes("/dashboard")) {
      if (Number(totalUser) > 0) {
        if (userData.data === null || !userData.data) {
          navigate("/");
        }
      }
    }
  }, [userData, Location, navigate, totalUser]);

  return (
    <>
      {Location.includes("/dashboard") && (
        <>
          {!Location.includes("/adduser") && (
            <TopNav setHamClick={setHamClick} hamClick={hamClick} />
          )}

          <div
            className={
              !Location.includes("/adduser")
                ? `${
                    hamClick ? "ml-[50px]" : "ml-[180px]"
                  } duration-200 py-5 px-5 bg-white !text-black`
                : ""
            }
          >
            <Routes>
              <Route
                path="/dashboard"
                element={<Dashboard setProgress={setProgress} />}
              />
              <Route
                path="/dashboard/message"
                element={<Message setProgress={setProgress} />}
              />
              <Route
                path="/dashboard/message/:id"
                element={<EachMsg setProgress={setProgress} />}
              />
              <Route
                path="/dashboard/users"
                element={<Users setProgress={setProgress} />}
              />
              <Route
                path="/dashboard/reviews"
                element={<Reviews setProgress={setProgress} />}
              />
              <Route
                path="/dashboard/banner"
                element={<Banner setProgress={setProgress} />}
              />
              <Route
                path="/dashboard/countries"
                element={<Countries setProgress={setProgress} />}
              />
              <Route
                path="/dashboard/university"
                element={<University setProgress={setProgress} />}
              />
              <Route
                path="/dashboard/internship"
                element={<Work setProgress={setProgress} />}
              />
              <Route
                path="/dashboard/adduser"
                element={<Register setProgress={setProgress} />}
              />
            </Routes>
          </div>
        </>
      )}
    </>
  );
};

export default PageDashboard;

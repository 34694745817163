import React, { useEffect, useState } from "react";
import StudyAbroad from "./StudyAbroad";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { countries as importedCountries } from "../assets/CountryData";
import { data as importedUniversityData } from "../assets/UniversityData";
import { workData as importedWorkData } from "../assets/WorkData";
import { API_BASE_URL } from "../../../redux/config";

const Countries = ({ isStudy }) => {
  const pathName = useLocation().pathname;
  const [backendUniversityData, setBackendUniversityData] = useState([]);
  const [backendCountriesData, setBackendCountriesData] = useState([]);
  const [backendWorksData, setBackendWorksData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/countries/countries`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const fetchUniversity = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/university/university`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const fetchWorks = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/work/work`);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      const universityData = await fetchUniversity();
      setBackendUniversityData(universityData);

      const workData = await fetchWorks();
      setBackendWorksData(workData);

      const countriesData = await fetchCountries();
      setBackendCountriesData(countriesData);
      setLoading(false); // End loading
    };
    fetchData();
  }, []);

  const finalCountries =
    backendCountriesData?.length > 0 &&
    backendUniversityData?.length > 0 &&
    backendWorksData?.length > 0
      ? backendCountriesData
      : importedCountries;
  const finalUniversityData =
    backendCountriesData?.length > 0 &&
    backendUniversityData?.length > 0 &&
    backendWorksData?.length > 0
      ? backendUniversityData
      : importedUniversityData;
  const finalWorkData =
    backendCountriesData?.length > 0 &&
    backendUniversityData?.length > 0 &&
    backendWorksData?.length > 0
      ? backendWorksData
      : importedWorkData;

  const studyCountry = finalCountries
    .filter((country) =>
      finalUniversityData.some(
        (university) => university.countryid === country.id
      )
    )
    .map((country) => ({
      ...country,
      universities: finalUniversityData.filter(
        (university) => university.countryid === country.id
      ),
    }));

  const workCountry = finalCountries
    .filter((country) =>
      finalWorkData.some((university) => university.countryid === country.id)
    )
    .map((country) => ({
      ...country,
      universities: finalWorkData.filter(
        (university) => university.countryid === country.id
      ),
    }));

  const scrollUP = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollUP();
  }, [pathName]);

  return (
    <div>
      <StudyAbroad
        isStudy={isStudy}
        countries={isStudy ? studyCountry : workCountry}
        loading={loading}
      />
    </div>
  );
};

export default Countries;

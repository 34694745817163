import React, { useEffect, useRef, useState } from "react";
import TablePage from "./Table";
import { useDispatch, useSelector } from "react-redux";
import {
  postcountry,
  getcountrys,
  deletecountry,
  updatecountry,
} from "../../../../redux/slices/countriesSlice";
import {
  // percentCompletedValue,
  setOnProgressChangeCallback,
} from "../../../../redux/slices/countriesSlice";
import { toast } from "sonner";

const Page = () => {
  const [postUpload, setPostUpload] = useState(0);
  const dispatch = useDispatch();
  const userRef = useRef(false);
  const [finalData, setFinalData] = useState([]);
  const { countriesData } = useSelector((state) => state.countriesReducer);
  const data = [];

  const joinedData = finalData.map((countries) => {
    const matchingCategory = data.find(
      (category) => category.id === countries.category
    );
    return {
      ...countries,
      categoryData: matchingCategory,
    };
  });

  useEffect(() => {
    if (userRef.current === false) {
      dispatch(getcountrys());
    }
    return () => {
      userRef.current = true;
    };
  }, [dispatch]);
  useEffect(() => {
    if (postUpload >= 100) {
      setTimeout(() => {
        setPostUpload(0);
      }, 1000);
    }
  }, [postUpload]);

  useEffect(() => {
    let finalCategoryData = countriesData.map((item, index) => ({
      ...item,
      sn: index + 1,
    }));
    setFinalData(finalCategoryData);
  }, [countriesData]);

  const handelpostcountry = async (data) => {
    try {
      const response = await dispatch(postcountry(data));
      if (response.payload.id > 0) {
        toast.success("Country added successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot add countries");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelDelete = async (data) => {
    try {
      const response = await dispatch(deletecountry(data));
      if (response.payload > 0) {
        toast.warning("Country deleted successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot delete countries");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelUpdate = async (data) => {
    try {
      const response = await dispatch(updatecountry(data));
      if (response.payload.id > 0) {
        toast.success("Country updated successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot update countries");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };
  setOnProgressChangeCallback((newValue) => {
    setPostUpload(newValue);
  });

  return (
    <>
      <TablePage
        catouselData={joinedData[0]?.sn ? joinedData : []}
        handelPost={handelpostcountry}
        handelDelete={handelDelete}
        handelUpdate={handelUpdate}
        postUpload={postUpload}
        categoryDataDropdown={data}
      />
    </>
  );
};

export default Page;

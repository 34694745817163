import React, { useEffect, useRef } from "react";
import Widget from "./widget";
import Chart from "./Chart";
import "./css/Dashboard.css";
import Table from "../activities/message/Page";
import {
  getSixMonthReview,
  getSummaryCount,
} from "../../../redux/slices/statisticsSlice";
import { useDispatch, useSelector } from "react-redux";
import Reviews from "../contents/review/Page";
import "./css/chart.css";

const Page = () => {
  const dispatch = useDispatch();
  const { lastSixMonthReview, summaryCountData } = useSelector(
    (state) => state.statisticsReducer
  );

  const userRef = useRef(false);

  useEffect(() => {
    if (userRef.current === false) {
      dispatch(getSixMonthReview());
      dispatch(getSummaryCount());
    }
    return () => {
      userRef.current = true;
    };
  }, [dispatch]);

  return (
    <>
      <div>
        <div className="flex flex-col gap-4">
          <div className="widgets flex flex-wrap gap-4">
            <Widget
              type="countries"
              dataBack={summaryCountData.countries || 0}
            />
            <Widget
              type="universities"
              dataBack={summaryCountData.universities || 0}
            />
            <Widget type="works" dataBack={summaryCountData.works || 0} />
            <Widget type="reviews" dataBack={summaryCountData.reviews || 0} />
          </div>
          <div className="charts flex gap-4">
            <div className="w-[55%] rounded-md h-[430px]">
              <Chart
                title="Last 6 Months (Reviews)"
                aspect={2 / 1}
                data={lastSixMonthReview}
              />
            </div>
            <div className="w-[45%] chart px-3 pt-3 rounded-md overflow-auto h-[430px]">
              <Reviews isDashboard={true} />
            </div>
          </div>
          <div className="listContainer">
            <div className="listTitle pb-2">Latest Messages</div>
            <Table />
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;

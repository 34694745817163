import React from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { API_BASE_URL } from "../../../redux/config";
import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi"; // Import an icon

export default function EachUniversityModal({ btnref, data, isUniversity }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();
  const { name } = useParams();
  const handleInquire = () => {
    navigate("/contacts", {
      state: { isUniversity, country: name, university: data.id },
    });
  };

  return (
    <>
      <Button onPress={onOpen} ref={btnref} className="hidden">
        Open Modal
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="w-full md:w-1/2 mb-[25vh] md:mb-0"
        size="xl"
        onClose={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="!p-0">
                <img
                  src={
                    !data?.image?.includes("http")
                      ? API_BASE_URL + "/" + data.image
                      : data.image
                  }
                  alt={data.name}
                  className="w-full h-[250px] object-cover rounded"
                />
                <div>
                  <b className="px-3">{data.name}</b>
                  <p className="max-h-[30vh] overflow-auto text-justify px-3">
                    {data.description}
                  </p>
                </div>

                <div className="px-3 pb-3 flex justify-between items-end">
                  <Button
                    size="sm"
                    onPress={handleInquire}
                    className="bg-blue-500 hover:bg-blue-600 text-white flex items-center gap-2 transition duration-150"
                  >
                    <HiOutlineMail className="text-lg" />
                    Inquire
                  </Button>
                  <p className="text-gray-500 px-3 text-right -mt-3 pb-2 text-xs">
                    {new Date(data.date).toLocaleDateString()}
                  </p>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import TablePage from "./Table";
import { useDispatch, useSelector } from "react-redux";
import {
  postContacts,
  getContacts,
  deleteContacts,
  updateunreadcontent,
  getUnreadMsg,
} from "../../../../redux/slices/messageSlice";
import { toast } from "sonner";

const Page = (props) => {
  const { MsgId } = props;
  const dispatch = useDispatch();
  const userRef = useRef(false);
  const [finalData, setFinalData] = useState([]);
  const { contactData } = useSelector((state) => state.messageReducer);

  useEffect(() => {
    if (userRef.current === false) {
      dispatch(getContacts());
      dispatch(getUnreadMsg());
    }
    return () => {
      userRef.current = true;
    };
  }, [dispatch]);

  useEffect(() => {
    let finalCategoryData = contactData.map((item, index) => ({
      ...item,
      sn: index + 1,
    }));
    setFinalData(finalCategoryData);
  }, [contactData]);

  const handelpost = async (data) => {
    try {
      const response = await dispatch(postContacts(data));
      if (response.payload?.success) {
        toast.success("Send message successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot send message");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelDelete = async (data) => {
    try {
      const response = await dispatch(deleteContacts(data));
      if (response.meta?.requestStatus === "fulfilled") {
        toast.warning("Message deleted successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot delete message");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelUpdate = async (data) => {
    try {
      await dispatch(updateunreadcontent(data));
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };
  return (
    <>
      <TablePage
        contentData={finalData[0]?.sn ? finalData : []}
        handelPost={handelpost}
        handelDelete={handelDelete}
        handelUpdate={handelUpdate}
        MsgId={MsgId}
      />
    </>
  );
};

export default Page;

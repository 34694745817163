import React, { useRef, useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Select,
  SelectItem,
  Textarea,
  Progress,
} from "@nextui-org/react";
import { PlusIcon } from "../../../common/components/Tables/Icons/PlusIcon";

export default function ModalApp(props) {
  const {
    handelPost,
    updateBtnRef,
    handelUpdate,
    updateData,
    setUpdateData,
    postUpload,
    countryDataDropdown,
  } = props;
  const closeBtnRef = useRef();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [universitiesData, setuniversitiesData] = useState({
    name: "",
    countryid: "",
    description: "",
    image: null,
  });

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    countryid: "",
  });

  useEffect(() => {
    setuniversitiesData({
      name: updateData.status ? updateData.data.name : "",
      countryid: updateData.status ? updateData.data.countryid : "",
      description: updateData.status ? updateData.data.description : "",

      image: updateData.status ? updateData.data.image : null,
    });
  }, [updateData]);

  const universitiesDataChange = (e) => {
    if (e.target.name === "image") {
      setuniversitiesData({
        ...universitiesData,
        image: e.target.files[0],
      });
    } else {
      setuniversitiesData({
        ...universitiesData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const clearuniversitiesData = () => {
    setuniversitiesData({
      name: "",
      countryid: "",
      description: "",
      image: null,
    });
    setUpdateData({ status: false, data: {} });
    setValidationErrors({
      name: "",
      countryid: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationErrors = {
      name: "",
      countryid: "",
      description: "",
      image: "",
    };

    if (!universitiesData.name.trim()) {
      newValidationErrors.name = "University name is required";
      isValid = false;
    }

    if (!universitiesData.description.trim()) {
      newValidationErrors.description = "University description is required";
      isValid = false;
    }
    if (!universitiesData.image) {
      newValidationErrors.image = "University image is required";
      isValid = false;
    }
    // Image is optional, but if selected, it should be less than 800KB
    if (universitiesData?.image?.size > 800 * 1024) {
      newValidationErrors.image = "Image size should not exceed 800KB";
      isValid = false;
    }

    if (universitiesData.countryid.length < 1) {
      newValidationErrors.countryid = "University country is required";
      isValid = false;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  const universitiesDataSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (updateData.status) {
        handelUpdate({ ...universitiesData, id: updateData.data.id });
      } else {
        handelPost(universitiesData);
      }
    }
  };
  useEffect(() => {
    if (postUpload === 100) {
      setTimeout(() => {
        closeBtnRef.current?.click();
      }, 2000);
    }
  }, [postUpload]);

  return (
    <>
      <Button
        radius="sm"
        onPress={onOpen}
        color="primary"
        endContent={<PlusIcon />}
        ref={updateBtnRef}
        className="!hidden"
      >
        Add New
      </Button>
      <Modal
        isOpen={isOpen}
        radius="sm"
        size="xl"
        onOpenChange={onOpenChange}
        onClose={() => {
          clearuniversitiesData();
          onOpenChange();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <form method="post" onSubmit={universitiesDataSubmit}>
              <ModalHeader className="flex flex-col gap-1 uppercase text-slate-600 dark:text-slate-200">
                Add university
              </ModalHeader>
              <ModalBody>
                <Input
                  type="text"
                  label="University Name"
                  radius="sm"
                  size="sm"
                  variant="underlined"
                  name="name"
                  value={universitiesData.name}
                  onChange={universitiesDataChange}
                />
                {validationErrors.name && (
                  <div className="text-red-500 errorFront">
                    {validationErrors.name}
                  </div>
                )}

                <div className="flex gap-3 w-full">
                  <div className="flex flex-col w-1/2 mt-2">
                    {updateData.status ? (
                      <Select
                        variant="underlined"
                        label="Select country"
                        className="w-full"
                        radius="sm"
                        name="countryid"
                        value={universitiesData.countryid}
                        onChange={universitiesDataChange}
                        defaultSelectedKeys={[
                          String(updateData.data.countryid),
                        ]}
                      >
                        {countryDataDropdown.map((e) => (
                          <SelectItem value={e.id} key={e.id}>
                            {e.name}
                          </SelectItem>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        variant="underlined"
                        label="Select country"
                        className="w-full"
                        radius="sm"
                        name="countryid"
                        value={universitiesData.countryid}
                        onChange={universitiesDataChange}
                      >
                        {countryDataDropdown.map((e) => (
                          <SelectItem value={e.id} key={e.id}>
                            {e.name}
                          </SelectItem>
                        ))}
                      </Select>
                    )}
                    {validationErrors.countryid && (
                      <div className="text-red-500 !mt-1 errorFront">
                        {validationErrors.countryid}
                      </div>
                    )}
                  </div>
                  <div className="w-1/2">
                    <Input
                      type="file"
                      variant="underlined"
                      label="University Image"
                      placeholder="E"
                      radius="none"
                      className="!p-0 paddintyzero"
                      accept=".jpg, .jpeg, .png, .gif, .bmp, .webp"
                      name="image"
                      onChange={universitiesDataChange}
                    />
                    {validationErrors.image && (
                      <div className="text-red-500 !mt-1 errorFront">
                        {validationErrors.image}
                      </div>
                    )}
                  </div>
                </div>
                <Textarea
                  variant="underlined"
                  label="University Description"
                  name="description"
                  value={universitiesData.description}
                  onChange={universitiesDataChange}
                />
                {validationErrors.description && (
                  <div className="text-red-500 errorFront">
                    {validationErrors.description}
                  </div>
                )}
                {postUpload > 0 && (
                  <Progress
                    aria-label="Downloading..."
                    size="sm"
                    value={postUpload}
                    className="mt-1"
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <div className="flex gap-3 flex-row-reverse">
                  <Button
                    color="primary"
                    size="sm"
                    radius="none"
                    className="rounded-sm !uppercase ml-2 tracking-wide"
                    type="submit"
                  >
                    Post
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    variant="bordered"
                    onPress={onClose}
                    radius="none"
                    className="rounded-sm !uppercase tracking-wide"
                    type="reset"
                    ref={closeBtnRef}
                    isDisabled={postUpload !== 0}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

import React from "react";
import { TbTargetArrow } from "react-icons/tb";
import Vision from "./Vision";
import Logo from "./Logo";

const Timeline = () => {
  return (
    <div className="timeline">
      <div className="container left">
        <div className="content relative">
          <div className="flex gap-4 items-center">
            <h2 className="text-nowrap text-xl font-bold w-[140px] flex justify-start gap-1.5 items-center ">
              <span>Who </span>
              <span className="text-[#FF0000]">We</span>
              <span>Are?</span>
              <div className="ml-1">
                <Logo />
              </div>
            </h2>
            <div className="h-[4px] bg-[#eaf2ff] absolute right-0 md:right-[-30px] md:w-[calc(100%_-_140px)] w-[calc(100%_-_175px)]"></div>
          </div>
          <p className="text-justify">
            With almost 10 years of experience and a wonderful team of QEAC
            Certified Education Counsellors, Milesway can give you all the
            guidance and services you need to study abroad. Our in-house experts
            will support and guide you in course & university selection, manage
            your study and visa application. We will start by understanding you
            and your goals, then our experts will help you find the right course
            according to your area of interest at an institution of your choice.
          </p>
        </div>
      </div>
      <div className="container right">
        <div className="content">
          <div className="flex gap-4 items-center md:justify-end">
            <div className="h-[4px] bg-[#eaf2ff] absolute md:left-[-30px] right-[0px] md:w-[calc(100%_-_130px)] w-[calc(100%_-_170px)]"></div>
            <div className="hidden md:block">
              <h2 className="text-nowrap  text-xl font-bold w-[130px] flex justify-end gap-1.5 items-center relative">
                <TbTargetArrow className="text-2xl text-[#FF0000] absolute left-[-14px]" />
                <span>Our </span>
                <span className="text-[#FF0000]">Mission</span>
              </h2>
            </div>
            <div className="md:hidden block">
              <h2 className="text-nowrap text-xl font-bold w-[130px] flex justify-start lg:justify-end gap-1.5 items-center relative">
                <span>Our </span>
                <span className="text-[#FF0000]">Mission</span>
                <TbTargetArrow className="text-2xl text-[#FF0000] absolute right-[-23px] lg:right-[-30px]" />
              </h2>
            </div>
          </div>
          <p className="text-justify">
            With almost 10 years of experience and a wonderful team of QEAC
            Certified Education Counsellors, Milesway can give you all the
            guidance and services you need to study abroad. Our in-house experts
            will support and guide you in course & university selection, manage
            your study and visa application. We will start by understanding you
            and your goals, then our experts will help you find the right course
            according to your area of interest at an institution of your choice.
          </p>
        </div>
      </div>
      <div className="container left">
        <div className="content">
          <div className="flex gap-4 items-center">
            <h2 className="text-nowrap text-xl font-bold w-[120px] flex justify-start gap-1.5 items-center mb-[2px]">
              <span>Our </span>
              <span className="text-[#FF0000]">Vision</span>
              <div className="scale-90 ml-1">
                <Vision />
              </div>
            </h2>
            <div className="h-[4px] bg-[#eaf2ff] absolute right-0 w-[calc(100%_-_160px)] md:right-[-30px] md:w-[calc(100%_-_120px)]"></div>
          </div>
          <p className="text-justify">
            With almost 10 years of experience and a wonderful team of QEAC
            Certified Education Counsellors, Milesway can give you all the
            guidance and services you need to study abroad. Our in-house experts
            will support and guide you in course & university selection, manage
            your study and visa application. We will start by understanding you
            and your goals, then our experts will help you find the right course
            according to your area of interest at an institution of your choice.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Timeline;

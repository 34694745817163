import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../../redux/config";

const EachCard = ({ data, isStudy }) => {
  return (
    <Link
      to={
        isStudy ? `/study-abroad/${data.id}` : `/internship-abroad/${data.id}`
      }
      className="max-w-[400px]"
    >
      <Card shadow="sm">
        <CardBody className="overflow-visible p-0">
          <Image
            shadow="sm"
            radius="lg"
            width="100%"
            alt={data.country}
            className="w-full object-cover h-[220px]"
            src={
              data?.image?.includes("uploads")
                ? API_BASE_URL + "/" + data.image
                : data.image
            }
          />
        </CardBody>
        <CardFooter className="text-small justify-between">
          <b>{data.name}</b>
        </CardFooter>
      </Card>
    </Link>
  );
};

export default EachCard;

import React from "react";
import EachCard from "./EachCard";

const CountryCards = ({ countries, isStudy }) => {
  return (
    <div className="gridContainerCard gap-6">
      {countries.map((e, index) => {
        return <EachCard key={e.id} data={e} isStudy={isStudy} />;
      })}
    </div>
  );
};

export default CountryCards;

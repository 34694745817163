import React, { useContext, useEffect, useRef } from "react";
import TopNavContent from "./TopNavContent";
import SideNav from "./SideNav";
import { getUnreadMsg } from "../../../redux/slices/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import UserContext from "../../../context/userContext";

const TopNav = ({ setHamClick, hamClick }) => {
  const { userData } = useContext(UserContext);
  const dispatch = useDispatch();

  const { unreadMsg } = useSelector((state) => state.messageReducer);
  const userRef = useRef(null);

  useEffect(() => {
    if (userRef.current === false) {
      dispatch(getUnreadMsg());
    }
    return () => {
      userRef.current = true;
    };
  }, [dispatch]);

  return (
    <div>
      <nav className="w-full overflow-auto h-[55px] ">
        <div className="w-full h-[55px] flex shadow dark:shadow-slate-600 bg-white dark:bg-[#121212] z-50 fixed top-0 left-0 right-0">
          <TopNavContent
            loginData={userData.data}
            setHamClick={setHamClick}
            hamClick={hamClick}
            unreadMsg={unreadMsg}
          />
        </div>
        <SideNav hamClick={hamClick} />
      </nav>
    </div>
  );
};

export default TopNav;

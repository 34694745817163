import React, { useEffect, useRef, useState } from "react";
import TablePage from "./Table";
import { useDispatch, useSelector } from "react-redux";
import {
  postwork,
  getworks,
  deletework,
  updatework,
} from "../../../../redux/slices/workSlice";
import {
  // percentCompletedValue,
  setOnProgressChangeCallback,
} from "../../../../redux/slices/workSlice";
import { toast } from "sonner";
import { getcountrys } from "../../../../redux/slices/countriesSlice";

const Page = () => {
  const [postUpload, setPostUpload] = useState(0);
  const dispatch = useDispatch();
  const userRef = useRef(false);
  const [finalData, setFinalData] = useState([]);
  const { countriesData } = useSelector((state) => state.countriesReducer);
  const { workData } = useSelector((state) => state.workReducer);

  useEffect(() => {
    if (userRef.current === false) {
      dispatch(getworks());
      dispatch(getcountrys());
    }
    return () => {
      userRef.current = true;
    };
  }, [dispatch]);
  useEffect(() => {
    if (postUpload >= 100) {
      setTimeout(() => {
        setPostUpload(0);
      }, 1000);
    }
  }, [postUpload]);

  useEffect(() => {
    let finalCategoryData = workData.map((item, index) => ({
      ...item,
      sn: index + 1,
    }));
    setFinalData(finalCategoryData);
  }, [workData]);

  const handelpostwork = async (data) => {
    try {
      const response = await dispatch(postwork(data));
      if (response.payload.id > 0) {
        toast.success("Internship added successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot add internship");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelDelete = async (data) => {
    try {
      const response = await dispatch(deletework(data));
      if (response.payload > 0) {
        toast.warning("Internship deleted successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot delete internship");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelUpdate = async (data) => {
    try {
      const response = await dispatch(updatework(data));
      if (response.payload.id > 0) {
        toast.success("Internship updated successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot update internship");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };
  setOnProgressChangeCallback((newValue) => {
    setPostUpload(newValue);
  });

  return (
    <>
      <TablePage
        catouselData={finalData[0]?.sn ? finalData : []}
        handelPost={handelpostwork}
        handelDelete={handelDelete}
        handelUpdate={handelUpdate}
        postUpload={postUpload}
        countryDataDropdown={countriesData}
      />
    </>
  );
};

export default Page;

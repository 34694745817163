import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const TopNav = () => {
  return (
    <div className="flex justify-center sm:justify-end gap-5 items-center w-full h-[35px] sm:pr-10 bg-[#EAF2FF]">
      <div className="h-[20px] w-[0.02rem] bg-slate-500"></div>
      <div className="flex gap-1 items-center text-black/70 mt-[1px]">
        <MdEmail className="text-lg mr-[2px]" />
        <div className="font-poppins text-xs md:text-sm">
          info@mileswayedu.com
        </div>
      </div>
      <div className="h-[20px] w-[0.02rem] bg-slate-500"></div>
      <div className="flex gap-1 items-center text-black/70 mt-[1px]">
        <FaPhoneAlt className="text-sm scale-110 mb-[0.1rem]" />
        <div className="font-poppins text-xs md:text-sm flex gap-2">
          <span className="block md:hidden">+977 9851339696</span>
          <span className="hidden md:block">+977 9851339696 ,</span>
          <span className="hidden md:block">+977 9851225506</span>
        </div>
      </div>
      <div className="h-[20px] w-[0.02rem] bg-slate-500"></div>
    </div>
  );
};

export default TopNav;

import React from "react";
import { Input, Button, Card } from "@nextui-org/react";
import { EyeFilledIcon } from "../../../assets/jsx/EyeFilledIcon.js";
import { MailIcon } from "../../../assets/jsx/MailIcon.js";
import { EyeSlashFilledIcon } from "../../../assets/jsx/EyeSlashFilledIcon.js";
import PersonIcon from "@mui/icons-material/Person";
import { IoArrowForwardOutline } from "react-icons/io5";
// import Logo from "@/public/svg/logo.svg";
// import Image from "next/image";
// import LoginWithGoogle from "./LoginWithGoogle";
import { Link } from "react-router-dom";

const Form = ({
  toggleVisibility,
  formRegisterData,
  handelRegister,
  formInputFieldChange,
  isVisible,
  errors,
}) => {
  return (
    <>
      <form className="min-h-[200px] w-[400px] rounded ">
        <Card
          radius="none"
          className="rounded-sm flex gap-5 flex-col p-8 font-poppins"
        >
          <div className="flex justify-between items-center">
            <h3 className="text-[var(--text-dark-color)] font-semibold tracking-wider text-2xl font-Orbitron">
              Sign Up
            </h3>
            {/* <Image src={Logo} height={50} width={130} alt="logo" /> */}
          </div>

          <div className="flex flex-col gap-3 -mt-1">
            <div className="flex gap-1 flex-col">
              <label
                htmlFor="name"
                className="text-sm text-[var(--text-dark-color)] cursor-pointer"
              >
                Name
              </label>
              <Input
                radius="sm"
                size="sm"
                variant="faded"
                // isRequired
                label="Name"
                type="name"
                name="name"
                value={formRegisterData.name}
                onChange={formInputFieldChange}
                endContent={
                  <PersonIcon className="text-2xl mb-1 scale-90 text-default-400 pointer-events-none flex-shrink-0" />
                }
              />
              {errors?.name?.length > 1 && (
                <div className="text-xs -mt-[0.1rem] -mb-2 font-poppins text-red-700 ml-2">
                  {errors.name}
                </div>
              )}
            </div>
            <div className="flex gap-1 flex-col">
              <label
                htmlFor="email"
                className="text-sm  text-[var(--text-dark-color)] cursor-pointer"
              >
                Email
              </label>
              <Input
                radius="sm"
                size="sm"
                variant="faded"
                // isRequired
                label="Email"
                name="email"
                value={formRegisterData.email}
                onChange={formInputFieldChange}
                type="email"
                endContent={
                  <MailIcon className="text-2xl mb-1 scale-90 text-default-400 pointer-events-none flex-shrink-0" />
                }
              />
              {errors?.email?.length > 1 && (
                <div className="text-xs -mt-[0.1rem] -mb-2 font-poppins text-red-700 ml-2">
                  {errors.email}
                </div>
              )}
            </div>
            <div className="flex gap-1 flex-col">
              <div className="flex justify-between">
                <label
                  htmlFor="email"
                  className="text-sm text-[var(--text-dark-color)] cursor-pointer"
                >
                  Password
                </label>
              </div>
              <Input
                label="Password"
                // isRequired
                radius="sm"
                size="sm"
                variant="faded"
                name="password"
                value={formRegisterData.password}
                onChange={formInputFieldChange}
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                  >
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl mb-1 scale-90 text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl mb-1 scale-90 text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                className="max-w-full"
              />
              {errors?.password?.length > 1 && (
                <div className="text-xs -mt-[0.1rem] -mb-2 font-poppins text-red-700 ml-2">
                  {errors.password}
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-2 justify-end">
            <Button
              fullWidth
              color="primary"
              radius="none"
              className="rounded-sm"
              type="submit"
              onClick={handelRegister}
              endContent={<IoArrowForwardOutline />}
            >
              Sign up
            </Button>
          </div>
          <div className="-mt-2">
            <p className="text-xs text-[var(--text-light-color)] tracking-wider">
              Already have account?{" "}
              <Link
                to={"/login"}
                className="text-blue-400 hover:text-blue-500 duration-150"
              >
                Sign in
              </Link>
            </p>
          </div>
          {/* <div className="flex justify-center items-center w-full -my-1">
            <LoginWithGoogle />
          </div> */}
        </Card>
      </form>
    </>
  );
};

export default Form;

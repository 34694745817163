import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";
import React, { useRef } from "react";
import EachUniversityModal from "./EachUniversityModal";
import { API_BASE_URL } from "../../../redux/config";

const EachUnivertisy = ({ data, isUniversity }) => {
  const btnref = useRef(null);
  return (
    <>
      <div
        className="max-w-[400px] cursor-pointer pb-10"
        onClick={() => btnref.current.click()}
      >
        <Card shadow="sm">
          <CardBody className="overflow-visible p-0">
            <Image
              shadow="sm"
              radius="lg"
              width="100%"
              alt={"item.title"}
              className="w-full object-cover h-[220px]"
              src={
                data?.image?.includes("uploads")
                  ? API_BASE_URL + "/" + data.image
                  : data.image
              }
            />
          </CardBody>
          <CardFooter className="text-small justify-between">
            <b className="w-full line-clamp-1">{data?.name}</b>
          </CardFooter>
        </Card>
      </div>
      <>
        <EachUniversityModal
          btnref={btnref}
          data={data}
          isUniversity={isUniversity}
        />
      </>
    </>
  );
};

export default EachUnivertisy;

import React, { useEffect } from "react";
import Devider from "../Home/Devider";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import { TiHome } from "react-icons/ti";
import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";
import ImageVector from "./ImageVector";
import backgroundImage from "../../../assets/images/aboutus.jpg";
import Timeline from "./Timeline";
import ContactInfoPage from "../Home/ContactInfoPage";
import Services from "./Services";

const AboutUs = () => {
  const scrollUP = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollUP();
  }, []);
  return (
    <div className="min-h-[300px] pt-[55px] relative">
      <div className="absolute w-full right-0 left-0 top-[60px]">
        <Devider />
      </div>
      <div className="pt-[50px]">
        <div className="flex justify-center items-center flex-col gap-1">
          <h1 className="flex gap-3 font-bold text-2xl md:text-3xl">
            <span className="text-[#FF3D00]">About </span>
            <span>Us</span>
          </h1>
          <div className="z-30 ">
            <Breadcrumbs size="lg">
              <BreadcrumbItem
                className="!hover:text-black"
                startContent={<TiHome />}
              >
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem startContent={<FaUsers />}>
                About Us
              </BreadcrumbItem>
            </Breadcrumbs>
          </div>
          <div className="md:min-h-[200px] w-full xl:w-[80%] flex justify-center items-center relative">
            <div className="w-full h-full my-2 md:my-5 flex justify-center items-center gap-10">
              <div className="w-[96%] md:w-[60%] text-base md:text-lg text-center">
                With almost 10 years of experience and a wonderful team of QEAC
                Certified Education Counsellors, Milesway can give you all the
                guidance and services you need to study abroad. Our in-house
                experts will support and guide you in course & university
                selection, manage your study and visa application. We will start
                by understanding you and your goals, then our experts will help
                you find the right course according to your area of interest at
                an institution of your choice.
              </div>
              <div className="hidden lg:block">
                <div className="w-[40%]  flex justify-end">
                  <div className="w-[250px] h-full">
                    <img
                      src={backgroundImage}
                      loading="lazy"
                      className="imagecontenttest w-[280px] h-full object-cover absolute top-7 right-16 z-20"
                      alt="AboutUS"
                    />
                  </div>
                  <div className="h-full w-[280px] object-cover absolute top-0 right-0 z-10">
                    <ImageVector />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-full w-full min-h-[400px]">
            <div className="flex flex-col">
              <Timeline />
            </div>
          </div>
          <div className="my-16">
            <ContactInfoPage />
          </div>
          <Services />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import Form from "./Form.js";
import AvatarPage from "./Avatar.js";
import {
  registerUser,
  registerUserAvatar,
} from "../../../redux/slices/authSlice.js";
import OTPForm from "./OTPForm.js";
import axios from "axios";
import { API_BASE_URL } from "../../../redux/config.js";
import { Link } from "react-router-dom";

const Register = () => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [isChooseImage, setIsChooseImage] = useState(false);
  const homeLink = useRef(null);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const [formRegisterData, setFormRegisterData] = useState({
    email: "",
    password: "",
    name: "",
    image: "",
  });

  const formInputFieldChange = (e) => {
    if (e.target.name === "image") {
      setFormRegisterData({ ...formRegisterData, image: e.target.files[0] });
    } else {
      setFormRegisterData({
        ...formRegisterData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validateForm = () => {
    const { email, password, name } = formRegisterData;
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    if (!name) {
      newErrors.name = "Name is required";
    } else if (name.length < 3) {
      newErrors.name = "Name must be at least 3 characters";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handelRegister = async (e) => {
    e?.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (!formRegisterData.image) {
      setIsChooseImage(true);
      return;
    }
    try {
      let response;
      if (formRegisterData.image instanceof File) {
        response = await dispatch(registerUser(formRegisterData));
      } else {
        response = await dispatch(registerUserAvatar(formRegisterData));
      }

      if (response.error) {
        if (response.error.message) {
          toast.error("User with this email already exists");
        } else if (
          response.error.code &&
          response.error.code.includes("ERR_BAD_REQUEST")
        ) {
          toast.error("Invalid email or password");
        }
        return;
      }

      if (response.payload.token) {
        localStorage.setItem("token", JSON.stringify(response.payload.token));
        setFormRegisterData({
          email: "",
          password: "",
          name: "",
          image: "",
        });
        setIsChooseImage(false);
        homeLink?.current?.click();
        toast.success("User registered successfully");
      }

      if (response.payload?.error) {
        toast.error(response.payload.error);
      }
    } catch (error) {
      toast.error("Some error occurred, try again later");
    }
  };

  const setSelectedImage = (image) => {
    setFormRegisterData({ ...formRegisterData, image });
  };

  const [showOTP, setShowOTP] = useState(false);

  const sendOTP = async (e) => {
    let email = formRegisterData.email;
    if (!email) {
      toast.warning("Please fill in your email.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/user/register/send-otp`,
        { email }
      );
      if (response.data.success) {
        localStorage.setItem("email", email);
        setShowOTP(true);
      } else {
        toast.error(response.data.message || "Failed to send OTP.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
    }
  };

  return (
    <>
      {showOTP ? (
        <>
          <OTPForm handelRegister={handelRegister} />
        </>
      ) : (
        <>
          {!isChooseImage ? (
            <div className="h-[100vh] gap-2 flex flex-col justify-center items-center">
              <Form
                toggleVisibility={toggleVisibility}
                handelRegister={handelRegister}
                formInputFieldChange={formInputFieldChange}
                formRegisterData={formRegisterData}
                isVisible={isVisible}
                setIsChooseImage={setIsChooseImage}
                errors={errors}
              />
            </div>
          ) : (
            <AvatarPage
              setIsChooseImage={setIsChooseImage}
              setSelectedImage={setSelectedImage}
              formRegisterData={formRegisterData}
              sendOTP={sendOTP}
              handelRegister={handelRegister}
            />
          )}
        </>
      )}
      <Link to={"/"} className="hidden" ref={homeLink}>
        Home
      </Link>
    </>
  );
};

export default Register;

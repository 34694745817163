import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./page/Home/Home";
import AboutUs from "./page/About/AboutUs.js";
import Universities from "./page/Universities/Universities.js";
import Navigation from "./common/navigation/Navigation";
import Footer from "./common/navigation/Footer";
import Contact from "./page/Contact/Contact.js";
import Login from "./page/Login/Login.js";
import Register from "./page/register/page.js";
import Countries from "./page/Countries.js/Countries.js";

const PageUser = () => {
  const Location = useLocation().pathname;

  return (
    <div>
      {!Location.toLowerCase().includes("/dashboard") && <Navigation />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/study-abroad" element={<Countries isStudy={true} />} />
        <Route
          path="/internship-abroad"
          element={<Countries isStudy={false} />}
        />

        <Route
          path="/study-abroad/:name"
          element={<Universities isUniversity={true} />}
        />
        <Route
          path="/internship-abroad/:name"
          element={<Universities isUniversity={false} />}
        />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/register" element={<Register />} />
      </Routes>
      {!Location.toLowerCase().includes("/dashboard") && <Footer />}
    </div>
  );
};

export default PageUser;

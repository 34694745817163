import React, { useEffect, useState, useMemo, useCallback } from "react";
import Devider from "../Home/Devider";
import { BreadcrumbItem, Breadcrumbs, Spinner } from "@nextui-org/react";
import { TiHome } from "react-icons/ti";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaUniversity } from "react-icons/fa";
import EachUniversityPage from "./EachUnivertityPage";
import EachCard from "./EachCard"; // Assuming EachCard component exists
import { MdOutlineWork } from "react-icons/md";
import axios from "axios";
import { API_BASE_URL } from "../../../redux/config"; // Update this to your actual config file path
// import { data as importedUniversityData } from "../assets/UniversityData";
// import { workData as importedWorkData } from "../assets/WorkData";

const Universities = ({ isUniversity }) => {
  const { name } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const pathName = useLocation().pathname;

  const scrollUP = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    scrollUP();
  }, [pathName, scrollUP]);

  const fetchUniversities = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/university/university`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching universities:", error);
      return [];
    }
  };

  const fetchWorks = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/work/work`);
      return response.data;
    } catch (error) {
      console.error("Error fetching works:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const universityData = await fetchUniversities();
      const workData = await fetchWorks();

      // if (universityData?.length > 0 && workData?.length > 0) {
      //   setData(isUniversity ? universityData : workData);
      // } else {
      //   setData(isUniversity ? importedUniversityData : importedWorkData);
      // }
      if (isUniversity) {
        setData(universityData || []);
      } else {
        setData(workData || []);
      }
      setLoading(false);
    };

    fetchData();
  }, [isUniversity]);

  const groupedData = useMemo(() => {
    return data.reduce((acc, item) => {
      const { countryid, country_name, ...rest } = item;
      const existingCountry = acc.find(
        (group) =>
          group.country_name.toLowerCase() === country_name.toLowerCase()
      );

      if (existingCountry) {
        existingCountry.data.push(rest);
      } else {
        acc.push({
          countryid,
          country_name,
          data: [rest],
        });
      }

      return acc;
    }, []);
  }, [data]);

  // Extract the data based on country_name
  const idData = useMemo(() => {
    return name
      ? groupedData.find(
          (group) => group.country_name.toLowerCase() === name.toLowerCase()
        )
      : null;
  }, [name, groupedData]);

  // Filter out the data with the same country_name if present
  const restData = useMemo(() => {
    return name
      ? groupedData.filter(
          (group) => group.country_name.toLowerCase() !== name.toLowerCase()
        )
      : groupedData;
  }, [name, groupedData]);

  return (
    <div className="min-h-[300px] pt-[55px] relative">
      <div className="absolute w-full right-0 left-0 top-[60px]">
        <Devider />
      </div>
      <div className="pt-[50px]">
        <div className="flex justify-center items-center flex-col gap-1">
          <h1 className="flex gap-3 font-bold text-2xl md:text-3xl">
            <span className="text-[#FF3D00]">
              {isUniversity ? "Universities" : "Internships"}
            </span>
          </h1>
          <div className="z-30">
            <Breadcrumbs size="lg">
              <BreadcrumbItem
                className="!hover:text-black"
                startContent={<TiHome />}
              >
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              {isUniversity ? (
                <BreadcrumbItem startContent={<FaUniversity />}>
                  Universities
                </BreadcrumbItem>
              ) : (
                <BreadcrumbItem startContent={<MdOutlineWork />}>
                  Internships
                </BreadcrumbItem>
              )}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-[300px]">
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          {name && idData ? (
            <>
              {/* <div className="w-[90%] mx-auto my-10 flex flex-col gap-3">
                <EachUniversityPage data={idData} isUniversity={isUniversity} />
              </div> */}

              <div className="w-[90%] mx-auto flex gap-7 flex-col">
                <h3 className="flex gap-3 font-bold text-xl mb-5">
                  <span className="text-[#FF3D00]">{idData.country_name}</span>
                  <span>
                    {" "}
                    All {isUniversity ? "Universities" : "Internship"}
                  </span>
                </h3>
                <div className="gridContainerCard gap-6">
                  {idData.data.map((item) => (
                    <EachCard
                      key={item.id}
                      data={item}
                      isUniversity={isUniversity}
                    />
                  ))}
                </div>
              </div>
              <div className="w-[90%] mx-auto">
                <hr />
              </div>

              <div className="w-[90%] mx-auto my-10 flex flex-col gap-3">
                <div className="flex "></div>
                {restData.map((group, index) => (
                  <EachUniversityPage
                    key={index}
                    data={group}
                    isUniversity={isUniversity}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="w-[90%] mx-auto my-10 flex flex-col gap-3">
              {groupedData?.map((group, index) => (
                <EachUniversityPage
                  key={index}
                  data={group}
                  isUniversity={isUniversity}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Universities;

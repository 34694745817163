import { Link, useLocation } from "react-router-dom";
import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { FaUsers } from "react-icons/fa";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { GiKnightBanner } from "react-icons/gi";
import "./css/SideNav.css";
import { FaUniversity } from "react-icons/fa";
import { FaGlobeAmericas } from "react-icons/fa";
import { MdOutlineWork, MdRateReview } from "react-icons/md";

const SideNav = (props) => {
  const { hamClick } = props;
  const loc = useLocation().pathname;

  return (
    <div
      className={`h-screen z-50 duration-100 fixed top-[55px] shadow dark:shadow-slate-600 ${
        !hamClick ? "left-0 w-[180px]" : "-left-[0px] w-[50px]"
      }`}
    >
      <div className="w-full h-[calc(100%_-_50px)] sidebar dark:!bg-[#121212] !z-50 !bg-white relative">
        <div className="center">
          <ul
            className={`!mb-2  ${
              hamClick ? "!pt-5 flex gap-2 flex-col" : "!pt-0"
            }`}
          >
            <p
              className={`${hamClick ? "hidden" : "block"} title tracking-wide`}
            >
              MAIN
            </p>
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc === "/dashboard"
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff]`
                    : ""
                }
              >
                <DashboardIcon className="icon" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Dashboard
                </span>
              </li>
            </Link>
            <p
              className={`${hamClick ? "hidden" : "block"} title tracking-wide`}
            >
              ACTIVITIES
            </p>

            <Link to="/dashboard/message" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc.includes("/dashboard/message")
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff]`
                    : ""
                }
              >
                <BiSolidMessageSquareDetail className="icon" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Message
                </span>
              </li>
            </Link>
            <Link to="/dashboard/users" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc.includes("/dashboard/users")
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff]`
                    : ""
                }
              >
                <FaUsers className="icon" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Users
                </span>
              </li>
            </Link>
            <p
              className={`${hamClick ? "hidden" : "block"} title tracking-wide`}
            >
              CONTENTS
            </p>

            <Link to="/dashboard/reviews" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc.includes("/dashboard/reviews")
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff]`
                    : ""
                }
              >
                <MdRateReview className="icon" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Reviews
                </span>
              </li>
            </Link>
            <Link to="/dashboard/banner" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc.includes("/dashboard/banner")
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff]`
                    : ""
                }
              >
                <GiKnightBanner className="icon text-3xl scale-[1.35]" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Banner
                </span>
              </li>
              <Link
                to="/dashboard/countries"
                style={{ textDecoration: "none" }}
              >
                <li
                  className={
                    loc === "/dashboard/countries"
                      ? `dark:!bg-[#363636]  !bg-[#dcd4ff]`
                      : ""
                  }
                >
                  <FaGlobeAmericas className="icon scale-90" />
                  <span className={`${hamClick ? "hidden" : "block"}`}>
                    Countries
                  </span>
                </li>
              </Link>
            </Link>

            <p
              className={`${hamClick ? "hidden" : "block"} title tracking-wide`}
            >
              STUDY / INTERNSHIP
            </p>
            <Link to="/dashboard/university" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc === "/dashboard/university"
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff]`
                    : ""
                }
              >
                <FaUniversity className="icon" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  University
                </span>
              </li>
            </Link>
            <Link to="/dashboard/internship" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc === "/dashboard/internship"
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff]`
                    : ""
                }
              >
                <MdOutlineWork className="icon" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Internship
                </span>
              </li>
            </Link>
          </ul>
        </div>
        {!hamClick && (
          <div className=" absolute bottom-20  flex justify-center items-center w-full mt-2 border-t">
            <p className="pt-2 text-center text-sm w-full px-4 text-black/70">
              Designed and Developed by G.A.S
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideNav;

import React from "react";
import background from "../../../assets/images/background.png";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";

const ContactInfoPage = () => {
  return (
    <div className="md:h-[650px] h-[470px] -mt-10 md:mt-0 w-full relative">
      <img
        loading="lazy"
        src={background}
        alt="background"
        className="h-full w-full absolute inset-0 object-contain"
      />
      <div className="flex justify-center items-center h-full w-full flex-col">
        <div className="w-[90%] md:w-[60%] lg:w-[45%] z-30 h-full flex justify-center items-center flex-col gap-5">
          <h1 className="flex gap-2 font-bold text-2xl lg:text-3xl xl:text-[3rem] justify-center items-center">
            <span>Making </span>
            <span className="text-[#FF3D00]"> Dreams </span>
            <span> a Reality</span>
          </h1>
          <p className="text-base lg:text-lg ml-1 text-center">
            Choosing Milesway means partnering with dedicated professionals who
            prioritize your success in studying or working abroad. Our
            comprehensive service from expert guidance and efficient processing
            to personalized counselling ensure every aspect of your journey is
            meticulously managed. With our support, confidently navigate
            international relocation and achieve your goals. Let Milesway be
            your trusted partner in making dreams a reality.
          </p>
          <div className="flex">
            <Link
              className={`bg-[#FF3D00] hover:tracking-wide flex scale-80 md:scale-100 justify-center items-center gap-1.5 text-white px-8 rounded-full hover:text-white duration-150 hover:bg-[#7668FF] relative py-[0.6rem]`}
              to={"/"}
            >
              <FaPhoneAlt className="mt-[1px]" />
              <span className="md:mb-[3px] font-semibold">+977 9851339696</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoPage;

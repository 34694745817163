import React, { useRef } from "react";
import Lottie from "lottie-react";
import counsoling from "../../../assets/lotifile/counsoling.json";
import processing from "../../../assets/lotifile/processing.json";
import guidance from "../../../assets/lotifile/guidance.json";

const LottieAnimation = ({ animationData }) => {
  const animationRef = useRef();

  const handleComplete = () => {
    setTimeout(() => {
      animationRef.current.goToAndPlay(0);
    }, 5000);
  };

  return (
    <Lottie
      lottieRef={animationRef}
      animationData={animationData}
      loop={false}
      onComplete={handleComplete}
    />
  );
};

const IntroCards = () => {
  return (
    <div className="min-h-[200px] w-full py-16 pb-24">
      <div className="w-full h-full flex justify-center items-center flex-col gap-12">
        <h1 className="font-semibold text-center text-2xl">
          We lead dreams into reality
        </h1>
        <div className="w-full h-full flex gap-16 justify-center items-center flex-col md:flex-row">
          <div className="w-[310px] group h-[350px] relative rounded shadow bg-[#EAF3FF] overflow-hidden">
            <div className="h-[300px] w-full group-hover:top-[-200px] duration-150 absolute top-0 right-0 left-0 flex justify-center items-center object-cover rounded-b-md bg-[#C7C1FF]">
              <div className="scale-[1.6] ml-2">
                <LottieAnimation animationData={counsoling} />
              </div>
            </div>
            <div className="h-[50px] absolute top-[300px] group-hover:top-[290px] duration-150 w-full py-[1px] flex flex-col justify-center group-hover:justify-end">
              <h2 className="text-xl px-2 flex font-semibold items-center tracking-wide">
                Counselling
              </h2>
              <div className="group-hover:h-[200px]">
                <p className="hidden px-2 -mt-0.5 group-hover:mt-0 group-hover:overflow-y-auto group-hover:line-clamp-none text-justify text-black/70 text-base h-full">
                  Milesway's counselling services are designed to provide you
                  with the support and confidence needed for a successful
                  transition abroad. Our dedicated team offers tailored guidance
                  to address your specific needs and concerns, helping you make
                  informed decisions and achieve your international goals. Let
                  us be your partner in this exciting journey.
                </p>
              </div>
            </div>
          </div>
          <div className="w-[310px] group h-[350px] relative rounded shadow bg-[#EAF3FF] overflow-hidden">
            <div className="h-[300px] w-full group-hover:top-[-200px] duration-150 absolute top-0 right-0 left-0 flex justify-center items-center object-cover rounded-b-md bg-[#C7C1FF]">
              <LottieAnimation animationData={processing} />
            </div>
            <div className="h-[50px] absolute top-[300px] group-hover:top-[290px] duration-150 w-full py-[1px] flex flex-col justify-center group-hover:justify-end">
              <h2 className="text-xl px-2 flex font-semibold items-center tracking-wide">
                Processing
              </h2>
              <div className="group-hover:h-[200px]">
                <p className="hidden px-2 -mt-0.5 group-hover:mt-0 group-hover:overflow-y-auto group-hover:line-clamp-none text-justify text-black/70 text-base h-full">
                  Simplify the complex process of moving abroad with Milesway's
                  efficient processing services. We handle all the necessary
                  paperwork, visa applications, and documentation, ensuring a
                  smooth and hassle-free experience. Trust our expertise to
                  manage the details, so you can focus on preparing for your new
                  adventure.
                </p>
              </div>
            </div>
          </div>
          <div className="w-[310px] group h-[350px] relative rounded shadow bg-[#EAF3FF] overflow-hidden">
            <div className="h-[300px] w-full group-hover:top-[-200px] duration-150 absolute top-0 right-0 left-0 flex justify-center items-center object-cover rounded-b-md bg-[#C7C1FF]">
              <LottieAnimation animationData={guidance} />
            </div>
            <div className="h-[50px] absolute top-[300px] group-hover:top-[290px] duration-150 w-full py-[1px] flex flex-col justify-center group-hover:justify-end">
              <h2 className="text-xl px-2 flex font-semibold items-center tracking-wide">
                Guidance
              </h2>
              <div className="group-hover:h-[200px]">
                <p className="hidden px-2 -mt-0.5 group-hover:mt-0 group-hover:overflow-y-auto group-hover:line-clamp-none text-justify text-black/70 text-base h-full">
                  At Milesway, we offer expert guidance to help you navigate the
                  path to studying or working abroad. Our experienced advisors
                  provide personalized advice to match your unique aspirations
                  and goals. From choosing the right destination to
                  understanding the requirements, we are here to support you
                  every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroCards;

import React from "react";

const ImageVector = () => {
  return (
    <svg viewBox="0 0 409 446" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M312.369 87.5986C353.003 129.253 389.572 169.718 402.923 219.704C416.274 269.094 407.567 326.816 377.382 364.305C347.198 402.39 296.116 420.242 247.357 432.738C199.177 445.235 153.32 452.971 117.911 436.904C82.5023 421.432 56.9615 382.157 37.2254 342.288C16.9089 301.823 1.81657 261.359 0.0751508 214.943C-1.08579 168.528 11.1041 116.162 43.6106 73.317C75.5366 30.472 127.779 -2.85178 176.539 0.718629C225.879 4.88411 271.156 46.5389 312.369 87.5986Z"
        fill="url(#paint0_linear_30_111)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_30_111"
          x1="0"
          y1="445.978"
          x2="443.897"
          y2="38.469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAF2FF" />
          <stop offset="1" stopColor="#C7C1FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ImageVector;

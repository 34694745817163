import React, { useState } from "react";
import { Rating } from "@mui/material";
import { Input, Textarea, Button } from "@nextui-org/react";
import { FaArrowRight } from "react-icons/fa";
import Lottie from "lottie-react";
import review from "../../../assets/lotifile/review.json";
import axios from "axios";
import { toast } from "sonner";
import { API_BASE_URL } from "../../../redux/config";

const Feedback = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [rating, setRating] = useState(null);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const validate = () => {
    const errors = {};
    if (!name) errors.name = "Please enter your name";
    if (!email) errors.email = "Please enter your email";
    if (!rating) errors.rating = "Please provide a rating";
    if (!description) errors.description = "Please enter your message";
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const reviewData = {
      name,
      email,
      rating,
      description,
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/review/review`,
        reviewData
      );
      if (response.status === 201 || response.status === 200) {
        toast.success("Thank you for your feedback.");
        setName("");
        setEmail("");
        setRating(null);
        setDescription("");
        setErrors({});
      } else {
        toast.error("Failed to submit review");
      }
    } catch (error) {
      toast.error("An error occurred while submitting your review");
    }
  };

  return (
    <div className="flex justify-center items-center gap-1 flex-col lg:flex-row">
      <div className="mx-auto w-[90%] lg:w-1/2 flex justify-center items-center">
        <div className="w-full max-w-[600px]">
          <Lottie animationData={review} />
        </div>
      </div>
      <form
        className="max-w-md mx-auto w-[90%] lg:w-1/2 my-14 p-6 bg-[#EAF2FF] shadow rounded"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-bold mb-4">Feedback Form</h2>
        <div className="flex flex-col gap-4">
          <div>
            <Input
              type="text"
              label="Your Name"
              size="sm"
              variant="bordered"
              className="!bg-white !rounded-lg"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {errors.name && (
              <span className="text-red-500 text-xs -mt-[3px] ml-[3px]">
                {errors.name}
              </span>
            )}
          </div>
          <div>
            <Input
              type="email"
              label="Your Email"
              size="sm"
              variant="bordered"
              className="!bg-white !rounded-lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errors.email && (
              <span className="text-red-500 text-xs -mt-[3px] ml-[3px]">
                {errors.email}
              </span>
            )}
          </div>
          <div className="flex gap-2 items-center">
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <label className="block mb-1">Rating:</label>
                <Rating
                  name="rating"
                  value={rating}
                  onChange={handleRatingChange}
                  size="medium"
                  precision={1}
                  color="primary"
                  required
                />
              </div>
              {errors.rating && (
                <span className="text-red-500 text-xs -mt-[3px] ml-[3px]">
                  {errors.rating}
                </span>
              )}
            </div>
          </div>
          <Textarea
            label="Your Message"
            placeholder="Enter your message here"
            variant="bordered"
            className="!bg-white !rounded-xl"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          {errors.description && (
            <span className="text-red-500 text-xs -mt-3 ml-[3px]">
              {errors.description}
            </span>
          )}
          <Button
            type="submit"
            variant="solid"
            endContent={<FaArrowRight className="mt-1 scale-90" />}
            color="primary"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Feedback;

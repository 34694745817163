import React from "react";
import Logo from "../../../assets/icons/biglogo.svg";
import DeviderIcon from "./DeviderIcon";

const Slogan = () => {
  return (
    <div className="px-2 mt-[-60px] md:-mt-10">
      <div className="md:h-[100px] mb-14 w-full flex justify-center items-center">
        <div className="md:h-[130px] h-[90px] w-full flex flex-col justify-center items-center">
          <div className="w-[200px] md:w-[350px] h-full flex justify-center items-center p-2">
            <img
              loading="lazy"
              src={Logo}
              height={150}
              width={350}
              alt="logo"
              className="ml-4 md:ml-0"
            />
          </div>
          <div className="h-full -mt-3 flex justify-center items-start flex-col gap-1 md:gap-3">
            {/* <div className="flex gap-3 font-bold text-xl md:text-2xl lg:text-3xl xl:text-[3rem] tracking-wide">
              <span>#Study</span> <span className="text-[#FF3D00]">Abroad</span>
            </div> */}
            <p className="italic ml-1 text-xs sm:text-sm md:text-base lg:text-lg text-black/80 font-semibold">
              We lead dreams into reality
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center items-center">
        <div className="w-1/2 h-[1px] bg-black/30"></div>
        <div className="h-[36px] w-[36px] mt-1 ml-1.5">
          <DeviderIcon />
        </div>
        <div className="w-1/2 h-[1px] bg-black/30"></div>
      </div>
    </div>
  );
};

export default Slogan;

import React, { useRef, useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Select,
  SelectItem,
  Textarea,
  Progress,
} from "@nextui-org/react";
import { PlusIcon } from "../../../common/components/Tables/Icons/PlusIcon";

export default function ModalApp(props) {
  const {
    handelPost,
    updateBtnRef,
    handelUpdate,
    updateData,
    setUpdateData,
    postUpload,
    countryDataDropdown,
  } = props;
  const closeBtnRef = useRef();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [workData, setworkData] = useState({
    name: "",
    countryid: "",
    description: "",
    workhr: "None",
    image: null,
  });

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    countryid: "",
  });

  useEffect(() => {
    setworkData({
      name: updateData.status ? updateData.data.name : "",
      countryid: updateData.status ? updateData.data.countryid : "",
      description: updateData.status ? updateData.data.description : "",
      workhr: updateData.status ? updateData.data.workhr : "None",
      image: updateData.status ? updateData.data.image : null,
    });
  }, [updateData]);

  const workDataChange = (e) => {
    if (e.target.name === "image") {
      setworkData({
        ...workData,
        image: e.target.files[0],
      });
    } else {
      setworkData({
        ...workData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const clearworkData = () => {
    setworkData({
      name: "",
      countryid: "",
      description: "",
      workhr: "None",
      image: null,
    });
    setUpdateData({ status: false, data: {} });
    setValidationErrors({
      name: "",
      countryid: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationErrors = {
      name: "",
      countryid: "",
      description: "",
      image: "",
      workhr: "None",
    };

    if (!workData.name.trim()) {
      newValidationErrors.name = "Internship name is required";
      isValid = false;
    }

    if (!workData.description.trim()) {
      newValidationErrors.description = "Internship description is required";
      isValid = false;
    }
    if (!workData.image) {
      newValidationErrors.image = "Internship image is required";
      isValid = false;
    }
    if (workData?.image?.size > 800 * 1024) {
      newValidationErrors.image = "Image size should not exceed 800KB";
      isValid = false;
    }

    if (workData.countryid.length < 1) {
      newValidationErrors.countryid = "Internship country is required";
      isValid = false;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  const workDataSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (updateData.status) {
        handelUpdate({ ...workData, id: updateData.data.id });
      } else {
        handelPost(workData);
      }
    }
  };
  useEffect(() => {
    if (postUpload === 100) {
      setTimeout(() => {
        closeBtnRef.current?.click();
      }, 2000);
    }
  }, [postUpload]);

  return (
    <>
      <Button
        radius="sm"
        onPress={onOpen}
        color="primary"
        endContent={<PlusIcon />}
        ref={updateBtnRef}
        className="!hidden"
      >
        Add New
      </Button>
      <Modal
        isOpen={isOpen}
        radius="sm"
        size="xl"
        onOpenChange={onOpenChange}
        onClose={() => {
          clearworkData();
          onOpenChange();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <form method="post" onSubmit={workDataSubmit}>
              <ModalHeader className="flex flex-col gap-1 uppercase text-slate-600 dark:text-slate-200">
                Add Internship
              </ModalHeader>
              <ModalBody>
                <div className="flex gap-3 w-full">
                  <div className="flex flex-col w-full">
                    <Input
                      type="text"
                      label="Internship Name"
                      radius="sm"
                      size="sm"
                      variant="underlined"
                      name="name"
                      value={workData.name}
                      onChange={workDataChange}
                    />
                    {validationErrors.name && (
                      <div className="text-red-500 !mt-1 errorFront">
                        {validationErrors.name}
                      </div>
                    )}
                  </div>
                  {/* <div className="flex flex-col w-1/2">
                    <Input
                      type="text"
                      label="Work Time"
                      radius="sm"
                      size="sm"
                      variant="underlined"
                      name="workhr"
                      value={workData.workhr}
                      onChange={workDataChange}
                    />
                    {validationErrors.workhr && (
                      <div className="text-red-500 !mt-1 errorFront">
                        {validationErrors.workhr}
                      </div>
                    )}
                  </div> */}
                </div>

                <div className="flex gap-3 w-full">
                  <div className="flex flex-col w-1/2 mt-2">
                    {updateData.status ? (
                      <Select
                        variant="underlined"
                        label="Select Country"
                        className="w-full"
                        radius="sm"
                        name="countryid"
                        value={workData.countryid}
                        onChange={workDataChange}
                        defaultSelectedKeys={[
                          String(updateData.data.countryid),
                        ]}
                      >
                        {countryDataDropdown.map((e) => (
                          <SelectItem value={e.id} key={e.id}>
                            {e.name}
                          </SelectItem>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        variant="underlined"
                        label="Select Country"
                        className="w-full"
                        radius="sm"
                        name="countryid"
                        value={workData.countryid}
                        onChange={workDataChange}
                      >
                        {countryDataDropdown.map((e) => (
                          <SelectItem value={e.id} key={e.id}>
                            {e.name}
                          </SelectItem>
                        ))}
                      </Select>
                    )}
                    {validationErrors.countryid && (
                      <div className="text-red-500 !mt-1 errorFront">
                        {validationErrors.countryid}
                      </div>
                    )}
                  </div>
                  <div className="w-1/2">
                    <Input
                      type="file"
                      variant="underlined"
                      label="Internship Image"
                      placeholder="E"
                      radius="none"
                      className="!p-0 paddintyzero"
                      accept=".jpg, .jpeg, .png, .gif, .bmp, .webp"
                      name="image"
                      onChange={workDataChange}
                    />
                    {validationErrors.image && (
                      <div className="text-red-500 !mt-1 errorFront">
                        {validationErrors.image}
                      </div>
                    )}
                  </div>
                </div>
                <Textarea
                  variant="underlined"
                  label="Internship Description"
                  name="description"
                  value={workData.description}
                  onChange={workDataChange}
                />
                {validationErrors.description && (
                  <div className="text-red-500 errorFront">
                    {validationErrors.description}
                  </div>
                )}
                {postUpload > 0 && (
                  <Progress
                    aria-label="Downloading..."
                    size="sm"
                    value={postUpload}
                    className="mt-1"
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <div className="flex gap-3 flex-row-reverse">
                  <Button
                    color="primary"
                    size="sm"
                    radius="none"
                    className="rounded-sm !uppercase ml-2 tracking-wide"
                    type="submit"
                  >
                    Post
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    variant="bordered"
                    onPress={onClose}
                    radius="none"
                    className="rounded-sm !uppercase tracking-wide"
                    type="reset"
                    ref={closeBtnRef}
                    isDisabled={postUpload !== 0}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

import React from "react";
import HeroEachPage from "./HeroEachPage";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import image1 from "../../../assets/images/hero1.jpg";
import image2 from "../../../assets/images/hero2.jpg";
import "swiper/css/pagination";
import { API_BASE_URL } from "../../../redux/config";

const HeroSection = ({ carData }) => {
  const pages =
    carData?.length > 0
      ? carData.map((item) => ({
          ...item,
          image: API_BASE_URL + item.image,
        }))
      : [
          {
            id: 1,
            color_heading: "study",
            non_color_heading: "abroad",
            description:
              "At Milesway, we turn your dreams of studying abroad into reality. Our expert support ensures a smooth transition and a successful academic experience in your new destination. Join us and open the door to a world of opportunities.",
            image: image1,
          },
          {
            id: 2,
            color_heading: "work",
            non_color_heading: "abroad",
            description:
              "At Milesway, we turn your dreams of working abroad into reality. Our expert support ensures a smooth transition and a successful professional experience in your new destination. Join us and open the door to a world of opportunities.",
            image: image2,
          },
        ];

  return (
    <div id="herosection">
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        // autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
        className="overflow-visible h-[860px] lg:h-[690px]"
      >
        {pages.map((e) => (
          <SwiperSlide key={e.id}>
            <HeroEachPage data={e} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroSection;

import React, { useEffect, useRef, useState } from "react";
import TablePage from "./Table";
import { useDispatch, useSelector } from "react-redux";
import {
  postuniversity,
  getuniversitys,
  deleteuniversity,
  updateuniversity,
} from "../../../../redux/slices/universitySlice";
import {
  // percentCompletedValue,
  setOnProgressChangeCallback,
} from "../../../../redux/slices/universitySlice";
import { toast } from "sonner";
import { getcountrys } from "../../../../redux/slices/countriesSlice";

const Page = () => {
  const [postUpload, setPostUpload] = useState(0);
  const dispatch = useDispatch();
  const userRef = useRef(false);
  const [finalData, setFinalData] = useState([]);
  const { countriesData } = useSelector((state) => state.countriesReducer);
  const { universityData } = useSelector((state) => state.universityReducer);

  useEffect(() => {
    if (userRef.current === false) {
      dispatch(getuniversitys());
      dispatch(getcountrys());
    }
    return () => {
      userRef.current = true;
    };
  }, [dispatch]);
  useEffect(() => {
    if (postUpload >= 100) {
      setTimeout(() => {
        setPostUpload(0);
      }, 1000);
    }
  }, [postUpload]);

  useEffect(() => {
    let finalCategoryData = universityData.map((item, index) => ({
      ...item,
      sn: index + 1,
    }));
    setFinalData(finalCategoryData);
  }, [universityData]);

  const handelpostuniversity = async (data) => {
    try {
      const response = await dispatch(postuniversity(data));
      if (response.payload.id > 0) {
        toast.success("University added successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot add universities");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelDelete = async (data) => {
    try {
      const response = await dispatch(deleteuniversity(data));
      if (response.payload > 0) {
        toast.warning("University deleted successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot delete universities");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelUpdate = async (data) => {
    try {
      const response = await dispatch(updateuniversity(data));
      if (response.payload.id > 0) {
        toast.success("University updated successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot update universities");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };
  setOnProgressChangeCallback((newValue) => {
    setPostUpload(newValue);
  });

  return (
    <>
      <TablePage
        catouselData={finalData[0]?.sn ? finalData : []}
        handelPost={handelpostuniversity}
        handelDelete={handelDelete}
        handelUpdate={handelUpdate}
        postUpload={postUpload}
        countryDataDropdown={countriesData}
      />
    </>
  );
};

export default Page;

import React, { useRef, useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Progress,
} from "@nextui-org/react";
import { PlusIcon } from "../../../common/components/Tables/Icons/PlusIcon";

export default function ModalApp(props) {
  const {
    handelPost,
    updateBtnRef,
    handelUpdate,
    updateData,
    setUpdateData,
    postUpload,
  } = props;
  const closeBtnRef = useRef();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [countriesData, setcountriesData] = useState({
    name: "",
    image: null,
  });

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    image: "",
  });

  useEffect(() => {
    setcountriesData({
      name: updateData.status ? updateData.data.name : "",
      image: updateData.status ? updateData.data.image : null,
    });
  }, [updateData]);

  const countriesDataChange = (e) => {
    if (e.target.name === "image") {
      const file = e.target.files[0];
      if (file && file.size > 800 * 1024) {
        // If the file size is larger than 800KB
        setValidationErrors((prev) => ({
          ...prev,
          image: "Image size should not exceed 800KB",
        }));
        setcountriesData({ ...countriesData, image: null });
      } else {
        setValidationErrors((prev) => ({
          ...prev,
          image: "", // Clear any previous error messages
        }));
        setcountriesData({ ...countriesData, image: file });
      }
    } else {
      setcountriesData({ ...countriesData, [e.target.name]: e.target.value });
    }
  };

  const clearcountriesData = () => {
    setcountriesData({
      name: "",
      image: null,
    });
    setUpdateData({ status: false, data: {} });
    setValidationErrors({
      name: "",
      image: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationErrors = {
      name: "",
      image: "",
    };

    if (!countriesData.name.trim()) {
      newValidationErrors.name = "Country name is required";
      isValid = false;
    }

    // Image is optional, but if selected, it should be less than 800KB
    if (countriesData.image && countriesData?.image?.size > 800 * 1024) {
      newValidationErrors.image = "Image size should not exceed 800KB";
      isValid = false;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  const countriesDataSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (updateData.status) {
        handelUpdate({ ...countriesData, id: updateData.data.id });
      } else {
        handelPost(countriesData);
      }
    }
  };

  useEffect(() => {
    if (postUpload === 100) {
      setTimeout(() => {
        closeBtnRef.current?.click();
      }, 2000);
    }
  }, [postUpload]);

  return (
    <>
      <Button
        radius="sm"
        onPress={onOpen}
        color="primary"
        endContent={<PlusIcon />}
        ref={updateBtnRef}
        className="!hidden"
      >
        Add New
      </Button>
      <Modal
        isOpen={isOpen}
        radius="sm"
        size="xl"
        onOpenChange={onOpenChange}
        onClose={() => {
          clearcountriesData();
          onOpenChange();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <form method="post" onSubmit={countriesDataSubmit}>
              <ModalHeader className="flex flex-col gap-1 uppercase text-slate-600 dark:text-slate-200">
                Add country
              </ModalHeader>
              <ModalBody>
                <Input
                  type="text"
                  label="Country Name"
                  radius="sm"
                  size="sm"
                  variant="underlined"
                  name="name"
                  value={countriesData.name}
                  onChange={countriesDataChange}
                />
                {validationErrors.name && (
                  <div className="text-red-500 errorFront">
                    {validationErrors.name}
                  </div>
                )}

                <div className="flex gap-3 w-full flex-col">
                  <Input
                    type="file"
                    variant="underlined"
                    label="Country Image"
                    placeholder="E"
                    radius="none"
                    className="!p-0 paddintyzero"
                    accept=".jpg, .jpeg, .png, .gif, .bmp, .webp"
                    name="image"
                    onChange={countriesDataChange}
                  />
                  {validationErrors.image && (
                    <div className="text-red-500 errorFront">
                      {validationErrors.image}
                    </div>
                  )}
                </div>

                {postUpload > 0 && (
                  <Progress
                    aria-label="Downloading..."
                    size="sm"
                    value={postUpload}
                    className="mt-1"
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <div className="flex gap-3 flex-row-reverse">
                  <Button
                    color="primary"
                    size="sm"
                    radius="none"
                    className="rounded-sm !uppercase ml-2 tracking-wide"
                    type="submit"
                  >
                    Post
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    variant="bordered"
                    onPress={onClose}
                    radius="none"
                    className="rounded-sm !uppercase tracking-wide"
                    type="reset"
                    ref={closeBtnRef}
                    isDisabled={postUpload !== 0}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
